import React, { useEffect } from 'react';
import DoctorCards from '../../components/DoctorCards/DoctorCards';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import DoctorDetails from '../../components/DoctorCards/DoctorDetails/DoctorDetails';

const DoctorDetailsPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
      <Header />
      <DoctorDetails/>
      <Footer />
    </>
  );
};

export default DoctorDetailsPage;
