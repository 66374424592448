import React, { useEffect } from 'react';
import ProfileForm from './ProfileForm/ProfileForm';
import ProfileLeftDash from './ProfieLeftDassh/ProfileLeftDash';
import "./profile.css"
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/Features/Auth/AuthApi';

const Profile = () => {
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.AuthSlice);
  useEffect(() => {
    dispatch(getUser());
  }, []);
  useEffect(() => {
    if(!authState?.is_loading?.update_user && authState?.is_success.update_user){
      dispatch(getUser());
    }
  }, [authState?.is_loading?.update_user,authState?.is_success.update_user,dispatch]);
  return (
    <div className="profile__dash__wrapper">
      <div className="profile__dash__container layout">
        <ProfileLeftDash authState={authState} />
        <ProfileForm authState={authState}/>
      </div>
    </div>
  );
};

export default Profile;
