import {configureStore} from "@reduxjs/toolkit"
import landingPageSlice from "./Features/LandingPage/LandingPageSlice";
import authSlice from "./Features/Auth/AuthSlice";
import doctorsSlice from "./Features/Doctors/DoctorsSlice";
import appointmentSlice from "./Features/Appointment/AppointmentSlice";
import gallerySlice from "./Features/Gallery/GallerySlice";
import  blogPostsSlice  from "./Features/BlogPosts/BlogPostsSlice";
import seoSlice from "./Features/Seo/SeoSlice";
const store = configureStore({
    reducer:{
        AuthSlice:authSlice,
        LandingPageSlice:landingPageSlice,
        DoctorSlice:doctorsSlice,
        AppointmentSlice:appointmentSlice,
        GallerySlice:gallerySlice,
        BlogPostsSlice:blogPostsSlice,
        SeoSlice:seoSlice

    }
});

export default store;