import React from 'react'
import { Check, CreditCard, Info, MapPin, Star } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import Rating from 'react-rating';
import "./inddex.css"

const Card = ({data}) => {
    const navigate = useNavigate();
  return (
    <div className="doctor__card">
    <div className="doctor__img">
      <img src={data?.image } alt="" />
    </div>
    <div className="doctor__name">
      <p>{data?.doctor_name}</p>
      <div className="check__icon__wrapper">
        {' '}
        <Check />
      </div>
    </div>
    <p className="role">
      {data?.degree}
    </p>
    <p className="role designation">
      {data?.designation}
    </p>
    <div className="ratings">
            {/* @ts-ignore */}
            <Rating
              quiet
              readonly
              initialRating={!data?.star ? 0 : data?.star}
              emptySymbol={<Star color="#FFC600" />}
              fullSymbol={<Star color="#FFC600" fill="#FFC600" />}
            />

      <span>({data?.review_count})</span>
    </div>
    <div className="location box">
      <MapPin />
      <span>{data?.address}</span>
    </div>
    {/* <div className="location box">
      <Clock />
      <span> Available on Fri, 22 Mar</span>
    </div> */}
    <div className="location box">
      <CreditCard />
      <span>NMC No. {data?.nmc_reg_no}</span>
      <Info />
    </div>
    <div className="buttons__wrapper">
      <button type="button" className="view__profile" onClick={()=>navigate(`/doctor-details/${data?.id}`)}>
        View Profile
      </button>
      <button type="button" className="book__Now" onClick={()=>navigate(`/doctor-details/${data?.id}`)}>
        Book Now
      </button>
    </div>
  </div>
  )
}

export default Card
