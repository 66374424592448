import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import Blog from './pages/Blog/Blog';
import DoctorsPage from './pages/Doctores/DoctorsPage';
import DoctorDetailsPage from './pages/DoctorDetailsPage/DoctorDetailsPage';
import LoginPage from './pages/Login/LoginPage';
import RegisterPage from './pages/Register/RegisterPage';
import ContactPage from './pages/Contact/ContactPage';
import ProfilePage from './pages/Profile/ProfilePage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ChangePasswordPage from './pages/ChangePassword/ChangePasswordPage';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import GalleryPage from './pages/Gallery/GalleryPage';
import AlbumsPage from './pages/Albums/AlbumsPage';
import BlogDetailsPage from './pages/BlogDetails/BlogDetailsPage';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import NotFound from './components/NotFound/NotFound';
import AppointMentpdf from './components/Dashboard/BookedAppointement/AppointmentPdf/AppointMentpdf';
import SuspenseLoader from './shared/Loader/SuspenseLoader';

function App() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/about" element={<AboutPage />} /> */}
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog-details/:slug" element={<BlogDetailsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/album/:id" element={<AlbumsPage />} />
        <Route path="/doctors" element={<DoctorsPage />} />
        <Route path="/doctor-details/:id" element={<DoctorDetailsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route
          path="/view-appointment-details/:id"
          element={<AppointMentpdf />}
        />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/patient/password-reset/:token"
          element={<ResetPassword />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
