import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_VALIDATION } from '../../../helpers/formUtils';

const validationSchema = yup.object().shape({
  fullname: yup.string().required(REQUIRED_VALIDATION('Fullname')) 
  .min(5, 'Fullname must be at least 5 characters long')
  .max(35, 'Fullname must not be exceed 35 characters long'),
//   email: yup
//     .string()
//     .trim()
//     .email('Invalid Email')
//     .required(REQUIRED_VALIDATION('Email')),
    // dob:yup.date()
    // .required(REQUIRED_VALIDATION("Date of birth"))
    // .typeError('Date of birth is required')
    // .test('is-past-date', 'Date of birth must be in the past', (value) => {
    //   // Check if the date of birth is in the past
    //   return value ? value < new Date() : false;
    // }),
//   password: yup
//     .string()
//     .trim()
//     .required(REQUIRED_VALIDATION('Password'))
//     .matches(
//       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
//       'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
//     ),
    phone:yup.string()
    .required('Phone number is required'),
    // .matches(/^\+977\d{10}$/, 'Phone number must start with +977 and be exactly 10 digits'),
  address: yup.string().required(REQUIRED_VALIDATION('Address'))  
  .min(10, 'Address must be at least 10 characters long'),
  age: yup
    .number()
    .required('Age is required')
    .typeError('Age is required')
    .min(0, 'Age can not be negative')
    .test('min-age', 'Age must be at least 5 years old', (value) => {
      // Ensure age is at least 5
      return value >= 5;
    }),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: 'onTouched',
  reValidateMode: 'onChange',
};
