import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../apiConfigs/urlConfigs";
import axios from "axios";

export const getAllPosts = createAsyncThunk(
    'getAllPosts',
    async (page, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/all-posts?page=${page}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getAllCategories = createAsyncThunk(
    'getAllCategories',
    async (undefined, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/categories`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getAllTags = createAsyncThunk(
    'getAllTags',
    async (undefined, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/tags`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getBlogsByCategory = createAsyncThunk(
    'getBlogsByCategory',
    async (category, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/posts-category/${category}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const getBlogsByTag = createAsyncThunk(
    'getBlogsByTag',
    async (tag, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/posts-tag/${tag}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getBlogsBySlug = createAsyncThunk(
    'getBlogsBySlug',
    async (slug, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/post/${slug}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );