import { createSlice, isAction } from '@reduxjs/toolkit';
import { getAllAlbums, getAllVideos } from './GalleryApi';

const initialState = {
  loading: false,
  album_loading:false,
  albums: [],
  all_album:null,
  all_video_links:null,
  all_videos:[]
};

const gallerySlice = createSlice({
  name: 'gallerySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL ALBUMS
    builder.addCase(getAllAlbums.pending, (state, { payload }) => {
      state.loading = true;
      state.album_loading = true;
    });
    builder.addCase(getAllAlbums.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.album_loading = false;
      // state.albums = payload?.data;
      state.all_album = payload;

      if(state.all_album?.meta?.current_page ===1 ){
        state.albums = state.all_album?.data;

      }

      else if (state.all_album?.meta?.current_page > 1  && state.all_album?.meta?.current_page <= state.all_album?.meta?.last_page){
        state.albums = state.albums.concat(state.all_album?.data);
      }
      
    });
    builder.addCase(getAllAlbums.rejected, (state, { payload }) => {
      state.loading = false;
      state.album_loading = false;
    });

    //GET ALL VIDEO LINKS
    builder.addCase(getAllVideos.pending, (state, { payload }) => {
      state.loading = true;
      state.album_loading = false;
    });
    builder.addCase(getAllVideos.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.album_loading = false;
      state.all_video_links = payload;
      if(state.all_video_links?.meta?.current_page ===1 ){
        state.all_videos = state.all_video_links?.data;

      }
      
      else if (state.all_video_links?.meta?.current_page > 1  && state.all_video_links?.meta?.current_page <= state.all_video_links?.meta?.last_page){
        state.all_videos = state.all_videos.concat(state.all_video_links?.data);
      }
  
    });
    builder.addCase(getAllVideos.rejected, (state, { payload }) => {
      state.loading = false;
      state.album_loading = false;
    });
  },
});

export default gallerySlice.reducer;
