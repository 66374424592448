import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCategories,
  getAllPosts,
  getAllTags,
  getBlogsByCategory,
  getBlogsBySlug,
  getBlogsByTag,
} from './BlogPostsApi';

const initialState = {
  all_posts: null,
  loading: false,
  get_all_post:false,
  categories: null,

  tags: null,
  temp_posts:[],
  posts:[],
  single_blog:{}
};

const blogPostsSlice = createSlice({
  name: 'blogPostsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL BLOG POSTS
    builder.addCase(getAllPosts.pending, (state, { payload }) => {
      state.loading = true;
      state.get_all_post=false;
    });

    builder.addCase(getAllPosts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=true;
      state.posts = payload;
      if(payload.meta?.current_page === 1 ){
        state.all_posts = payload;
      }
      
      else if (payload?.meta?.current_page > 1  && payload?.meta?.current_page <= payload?.meta?.last_page){
        state.all_posts = payload;
      }
    });

    builder.addCase(getAllPosts.rejected, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
    });

    //GET ALL CATEGORIES
    builder.addCase(getAllCategories.pending, (state, { payload }) => {
      state.loading = true;
      state.get_all_post=false;
    });

    builder.addCase(getAllCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
      state.categories = payload?.data;
    });

    builder.addCase(getAllCategories.rejected, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
    });

    //GET ALL TAGS
    builder.addCase(getAllTags.pending, (state, { payload }) => {
      state.loading = true;
      state.get_all_post=false;
    });

    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
      state.tags = payload?.data;
    });

    builder.addCase(getAllTags.rejected, (state, { payload }) => {
      state.loading = false;
    });

    //GET POST BY CATEGORY
    builder.addCase(getBlogsByCategory.pending, (state, { payload }) => {
      state.loading = true;
    });

    builder.addCase(getBlogsByCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
      state.posts = payload;
      state.all_posts = payload;
      state.temp_posts = payload;
    });

    builder.addCase(getBlogsByCategory.rejected, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
    });

    //GET POST BY TAGS
    builder.addCase(getBlogsByTag.pending, (state, { payload }) => {
      state.loading = true;
      state.get_all_post=false;
    });

    builder.addCase(getBlogsByTag.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
      state.all_posts = payload;
      state.temp_posts = payload;
      state.posts = payload;
    });

    builder.addCase(getBlogsByTag.rejected, (state, { payload }) => {
      state.loading = false;
      state.get_all_post=false;
    });

        //GET POST BY SLUG
        builder.addCase(getBlogsBySlug.pending, (state, { payload }) => {
          state.loading = true;
        });
    
        builder.addCase(getBlogsBySlug.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.single_blog = payload?.data
        
        });
    
        builder.addCase(getBlogsBySlug.rejected, (state, { payload }) => {
          state.loading = false;
        });
  },
});

export default blogPostsSlice.reducer;
