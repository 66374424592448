import React, { useEffect } from 'react';
import ProfileLeftDash from '../Profile/ProfieLeftDassh/ProfileLeftDash';
import BookedAppointMentTable from './BookedAppointement/BookedAppointMentTable';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/Features/Auth/AuthApi';

const Dashboard = () => {
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.AuthSlice);
  useEffect(() => {
    dispatch(getUser());
  }, []);
  return (
    <div className="profile__dash__wrapper">
      <div className="profile__dash__container layout">
        <ProfileLeftDash authState={authState} />
        <BookedAppointMentTable />
      </div>
    </div>
  );
};

export default Dashboard;
