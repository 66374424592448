import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../apiConfigs/urlConfigs';

export const getAllAlbums = createAsyncThunk(
  'getAllAlbums',
  async (pageNumber, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/photos?page=${pageNumber}`);
      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllVideos = createAsyncThunk(
    'getAllVideos',
    async (pageNumber, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/videos?page=${pageNumber}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );
  
