import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../apiConfigs/urlConfigs';
import axiosInstance from '../../../apiConfigs/axiosInstance';
import { TosatMessage } from '../../../helpers/Toast';

export const registerUser = createAsyncThunk(
  'registerUser',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/register`, data);
      TosatMessage(res.data.data);
      return res.data;
    } catch (error) {
      TosatMessage(error.response.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  'loginUser',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/login`, data);
      TosatMessage(res.data);
      return res.data;
    } catch (error) {
      TosatMessage(error.response.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  'getUser',
  async (undefined, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`${BASE_URL}/get-profile`);
      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const logout = createAsyncThunk('logout', async (rejectWithValue) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}/logout`);
    TosatMessage(res.data.data);
    return res.data;
  } catch (error) {
    TosatMessage(error.response.data);
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`${BASE_URL}/update-profile`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      TosatMessage(res.data);
      return res.data;
    } catch (error) {
      TosatMessage(error.response.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  'changePassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`${BASE_URL}/change-password`, data);
      TosatMessage(res.data.data);
      return res.data;
    } catch (error) {
      TosatMessage(error.response.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/patient/password-reset/${data?.token}`,{password:data.password,password_confirmation:data.password_confirmation});

      TosatMessage(res.data.data);
      return res.data;
    } catch (error) {
      TosatMessage(error.response.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const sentPasswordResetEmail = createAsyncThunk(
  'sentPasswordResetEmail',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/patient/send-password-reset-mail`,data);
      TosatMessage(res.data.data);
      return res.data;
    } catch (error) {
      TosatMessage(error.response.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


