import React from 'react';
import Rating from 'react-rating';
import { Star } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { giveReview } from '../../../../../store/Features/Doctors/DoctorApi';
import LocalDb from '../../../../../helpers/LocalDB/LocalDb';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorText from '../../../../../shared/ErrorText/ErrorText';
// Create a validation schema
const reviewSchema = yup.object().shape({
  rating: yup
    .number()
    .min(1, 'Rating must be at least 1 star')
    .required('Rating is required'),
  review_text: yup
    .string()
    .min(10, 'Review must be at least 10 characters long')
    .required('Review text is required'),
});

const ReviewAddForm = () => {
  const token = LocalDb.fetch('access-token');
  const doctorState = useSelector((store) => store.DoctorSlice);
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();

  const { register, handleSubmit, watch, setValue, reset, formState } = useForm(
    {
      resolver: yupResolver(reviewSchema),
      mode: 'onTouched',
      reValidateMode: 'onChange',
    }
  );

  const { errors } = formState;

  const ratingValue = watch('rating');

  const ratingHandler = (n) => {
    if (ratingValue === 1 && n === 1) {
      setValue('rating', 0);
    } else {
      setValue('rating', n);
    }
  };

  const submitReview = (values) => {
    const data = {
      doctor_id: id,
      star: values?.rating,
      review: values?.review_text,
    };
    if (!token) {
      navigate(`/login?drId=${params?.id}`);
    } else {
      dispatch(giveReview(data));
      reset();
    }
  };

  return (
    <form className="revie-form" onSubmit={handleSubmit(submitReview)}>
      <h5 className="review-form-heading">Add Review</h5>
      <div className="message-rating">
        <div className="">
          {/* @ts-ignore */}
          <Rating
            quiet
            initialRating={ratingValue}
            emptySymbol={<Star color="#FFC600" />}
            fullSymbol={<Star color="#FFC600" fill="#FFC600" />}
            onClick={(i) => ratingHandler(i)}
          />
        </div>
        {errors?.rating && <ErrorText error={errors?.rating?.message} />}

        <textarea
          className="form-control"
          id="review"
          placeholder="Write review here"
          rows={4}
          {...register('review_text')}
        />
        {errors?.review_text && (
          <ErrorText error={errors?.review_text?.message} />
        )}
      </div>

      <button
        type="submit"
        className={`add-review-button ${
          doctorState?.loading && !doctorState?.reviewed ? 'disabled' : ''
        }`}
      >
        {doctorState?.loading && !doctorState?.reviewed ? (
          <div className="spinner"></div>
        ) : (
          'Add Review'
        )}
      </button>
    </form>
  );
};

export default ReviewAddForm;
