import React, { useEffect, useRef, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import './profileForm.css';
import {  updateProfile } from '../../../store/Features/Auth/AuthApi';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import ErrorText from '../../../shared/ErrorText/ErrorText';
import { formOptions } from './helper';

const ProfileForm = ({authState}) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, formState, setValue } = useForm(formOptions);
  const { errors } = formState;

  const hiddenInputRef = useRef(null);
  const [uploadedImageURL, setUploadedImageURL] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);


  const onSubmit = (values) => {
    const data = {
      ...values,
      image: fileInfo,
    };
    dispatch(updateProfile(data));
  };

  const [eyeState, setEyeState] = useState(false);
  const toggleEye = () => {
    setEyeState(!eyeState);
  };

  const clickupload = () => {
    hiddenInputRef.current.click();
  };


  const changeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileInfo(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImageURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (authState?.is_success.load_user) {
      const formattedDob = moment(authState?.user?.dob).format(
        'YYYY-MM-DD'
      );
      setValue('fullname', authState?.user?.fullname);
      setValue('email', authState?.user?.email);
      setValue('password', authState?.user?.password);
      setValue('gender', authState?.user?.gender);
      setValue('blood_group', authState?.user?.blood_group);
      setValue('dob', formattedDob);
      setValue('age', authState?.user?.age);
      setValue('address', authState?.user?.address);
      setValue('phone', authState?.user?.phone);
      setUploadedImageURL(authState?.user?.image);
    }
  }, [authState, authState?.is_success.load_user, setValue]);

  const disablePaasswordField =
    authState?.user?.password === undefined ||
    authState?.user?.password === 'undefined' ||
    authState?.user?.password === null ||
    authState?.user?.password === 'null';

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="profile___form__wrapper">
      <div className="pic__change">
        <div className="pic__wrapper">
          {
              !uploadedImageURL ? (<div className='user__letter__avatar'>{authState?.user?.fullname?.charAt(0)}</div>):( 
             <img src={uploadedImageURL} alt="userimage" />
           )
           } 
        
        </div>
        <div className="upload__button">
          <button
            type="button"
            onClick={clickupload}
            style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
          >
            <input
              type="file"
              ref={hiddenInputRef}
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => changeInput(e)}
            />
            Upload Image
          </button>
        </div>
      </div>
      <div className="profile__form__container">
        <div className="form__container ">
          <div className="left__section">
            <div className="form__row">
            <div className="input__group">
                <div className="group">
                  <label htmlFor="fullName">Fullname <span>*</span> </label>
                  <input
                    type="text"
                    name="fullname"
                    {...register('fullname')}
                    placeholder='Enter Fullname'
                    className={`${errors?.fullname && "is-invalid"}`}
                  />
                </div>
                {errors?.fullname && (
                  <ErrorText error={errors?.fullname?.message} />
                )}
                {/* <div className="error">Error</div> */}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="email">Email <span>*</span></label>
                  <input type="email" name="email" {...register('email')} 
                      // className={`${errors?.email && "is-invalid"}`}placeholder='Enter Email'
                      disabled={true}
                       />
                </div>
                {/* {errors?.email && <ErrorText error={errors?.email?.message} />} */}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="email">Date of Birth <span>*</span></label>
                  <input type="date" name="dob" {...register('dob')} 
                      // className={`${errors?.dob && "is-invalid"}`} 
                  />
                </div>
                {/* {errors?.dob && <ErrorText error={errors?.dob?.message} />} */}
              </div>
              <div className="input__group">
                <div
                  className={`group ${disablePaasswordField ? 'disable' : ''}`}
                >
                  <label htmlFor="password">Password</label>
                  <input
                    type={eyeState ? 'password' : 'text'}
                    name="password"
                    {...register('password')}
                    disabled={disablePaasswordField}
                  />
                  <span
                    className="passwordToggle"
                    id="passwordToggle"
                    onClick={toggleEye}
                  >
                    {eyeState ? <Eye /> : <EyeOff />}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="right__section">
            <div className="form__row">
            <div className="input__group">
                <div className="group">
                  <label htmlFor="phoneNumber">Phone Number <span>*</span></label>
                  <input type="text" name="phone" {...register('phone')} placeholder='+977XXXXXXXXXX'    className={`${errors?.phone && "is-invalid"}`} />
                </div>
                {errors?.phone && (
                  <ErrorText error={errors?.phone?.message} />
                )}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="address">Address <span>*</span></label>
                  <input type="text" name="address" {...register('address')} placeholder='Enter Address'     className={`${errors?.address && "is-invalid"}`} />
                </div>

                {errors?.address && (
                  <ErrorText error={errors?.address?.message} />
                )}
              </div>
              <div className="gender__age__wrapper">
                <div className="input__group">
                  <div className="group">
                    <label htmlFor="age">Age <span>*</span></label>
                    <input type="number" name="age" {...register('age')}placeholder='Enter Age'    className={`${errors?.age && "is-invalid"}`} />
                  </div>

                  {errors?.age && <ErrorText error={errors?.age?.message} />}
                </div>
                <div className="input__group">
                  <div className="group">
                    <label htmlFor="gender">Gender <span>*</span></label>
                    <select name="gender" {...register('gender')}>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Prefer not to say</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="blood_group">Blood Group <span>*</span></label>
                  <select name="blood_group" {...register('blood_group')}>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button type="submit" className="save__changes__button">
            Save Changes
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProfileForm;
