import React, { useState } from 'react';

import './changePasswordForm.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';
import ErrorText from '../../../shared/ErrorText/ErrorText';
import { formOptions } from './helper';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../../store/Features/Auth/AuthApi';
const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, formState ,reset} = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (values) => {
    dispatch(changePassword(values));
    // reset();

  };

  const [eyeState, setEyeState] = useState(true);
  const [confirmEyeState, setConfirmSysState] = useState(true);
  const [newPasswordEyeState,setNewPasswordEyeState] = useState(true);
  const toggleEye = () => {
    setEyeState(!eyeState);
  };
  const toggleNewPasswordEyeState = () => {
    setNewPasswordEyeState(!newPasswordEyeState);
  };
  const toggleConfirmEye = () => {
    setConfirmSysState(!confirmEyeState);
  };
  return (
    <div className="profile___form__wrapper">
      <form
        className="profile__form__container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form__container ">
          <div className="left__section">
            <div className="form__row">
              <div className="input__group">
                <div className="group">
                  <label htmlFor="password">Old Password</label>
                  <input
                    type={eyeState ? 'password' : 'text'}
                    name="password"
                    {...register('old_password')}
                  />
                  <span
                    className="passwordToggle"
                    id="passwordToggle"
                    onClick={toggleEye}
                  >
                    {eyeState ? <Eye /> : <EyeOff />}
                  </span>
                </div>

                {errors?.old_password && (
                  <ErrorText error={errors?.old_password?.message} />
                )}
              </div>
            </div>
          </div>
          <div className="right__section">
            <div className="form__row">
              <div className="input__group">
                <div className="group">
                  <label htmlFor="password">New Password</label>
                  <input
                    type={newPasswordEyeState ? 'password' : 'text'}
                    name="password"
                    {...register('new_password')}
                  />
                  <span
                    className="confirmPassword"
                    id="confirmPassword"
                    onClick={toggleNewPasswordEyeState}
                  >
                    {newPasswordEyeState ? <Eye /> : <EyeOff />}
                  </span>
                </div>

                {errors?.new_password && (
                  <ErrorText error={errors?.new_password?.message} />
                )}
              </div>
            </div>
            <div className="form__row">
              <div className="input__group">
                <div className="group">
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type={confirmEyeState ? 'password' : 'text'}
                    name="password"
                    {...register('new_password_confirmation')}
                  />
                  <span
                    className="confirmPassword"
                    id="confirmPassword"
                    onClick={toggleConfirmEye}
                  >
                    {confirmEyeState ? <Eye /> : <EyeOff />}
                  </span>
                </div>

                {errors?.new_password_confirmation && (
                  <ErrorText error={errors?.new_password_confirmation?.message} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          {' '}
          <button type="submit" className="save__changes__button">
            ChangePassword
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
