import React, { useEffect } from 'react'
import Regiser from '../../components/Register/Regiser'
import LocalDb from '../../helpers/LocalDB/LocalDb';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
  const token = LocalDb.fetch('access-token');
  const user = LocalDb.fetch("user");
  const navigate = useNavigate();

  useEffect(()=>{
    if(token && user){
      navigate("/")
    }
  },[token,user,navigate])
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <div>
      <Regiser/>
    </div>
  )
}

export default RegisterPage
