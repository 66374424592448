import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../apiConfigs/urlConfigs";
import { TosatMessage } from "../../../helpers/Toast";

export const getLandingPageTopbardetails = createAsyncThunk("getLandingPageTopbardetails",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/settings`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);

        
    }
});

export const getSocialMediaLinks = createAsyncThunk("getSocialMediaLinks",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/socialmedias`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);

        
    }
});

export const getFaqs = createAsyncThunk("getFaqs",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/faqs`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);
    }
});

export const getHeroSliderData = createAsyncThunk("getHeroSliderData",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/sliders`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);
    }
});

export const getNotices = createAsyncThunk("getNotices",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/notices`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);
    }
});

export const contact = createAsyncThunk("contact",async(data,{rejectWithValue})=>{
    try {
        const res = await axios.post(`${BASE_URL}/queries`,data);
        TosatMessage(res.data.data);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);
    }
});

export const getTestimonials = createAsyncThunk("getTestimonials",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/testimonials`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);

        
    }
});

