import React from 'react';
import './profileLeftDash.css';
import { BookOpen, Gift, Lock, LogOut, Mail, MapPin, User } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/Features/Auth/AuthApi';

const ProfileLeftDash = ({authState}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const formattedDob = moment(authState?.user?.dob).format(
    'D MMMM YYYY'
  );

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="profile__left__wrapper">
      <div className="profile__left__container">
        <div className="profile__left__header">
          <div className="img__wrapper">
            {
              !authState?.user?.image ? (<div className='profile__dash__no__image'>{authState?.user?.fullname?.charAt(0)}</div>):(   <img
                src={authState?.user?.image}
                alt=""
              />)
            }
         

          </div>
          <h3 className="user__name">{}</h3>
          <div className="birth__year box">
            <Gift />
            <span> {formattedDob}</span>
          </div>
          <div className="user__location box">
            <Mail />
            <span>{authState?.user?.email || "-"}</span>
          </div>
        </div>

        <ul className="profile__ul">
          <Link to={'/dashboard'}>
            <li
              className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
            >
              <BookOpen />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to={'/profile'}>
            <li
              className={`${location.pathname === '/profile' ? 'active' : ''}`}
            >
              <User />
              <span>Profile Settings</span>
            </li>
          </Link>
          <Link to={'/change-password'}>
            <li
              className={`${
                location.pathname === '/change-password' ? 'active' : ''
              }`}
            >
              <Lock />
              <span>Change Password</span>
            </li>
          </Link>

          <li onClick={handleLogout}>
            <LogOut />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileLeftDash;
