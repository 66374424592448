import { createSlice } from '@reduxjs/toolkit';
import {  getAllDoctors, getSpecificDoctor, giveReview } from './DoctorApi';

const initialState = {
  loading: false,
  reviewed:false,
  all_doctors: [],
  single_doctor: null,
  doctor_review:[],
  all_review:[]
};

const doctorSlice = createSlice({
  name: 'doctorSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL DOCTORS
    builder.addCase(getAllDoctors.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getAllDoctors.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.all_doctors = payload.data;
    });

    builder.addCase(getAllDoctors.rejected, (state, action) => {
      state.loading = false;
    });

    // GET SPECIFIC DOCTORS
    builder.addCase(getSpecificDoctor.pending, (state, { payload }) => {
      state.loading = true;
      state.reviewed = false;
    });
    builder.addCase(getSpecificDoctor.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reviewed = false;
      state.single_doctor = payload.data;
      state.doctor_review = payload.data.review;
      state.all_review=payload.data.review;
    });

    builder.addCase(getSpecificDoctor.rejected, (state, action) => {
      state.loading = false;
      state.reviewed = false;
    });

    // RVIEW A DOCTOR 
    builder.addCase(giveReview.pending, (state, { payload }) => {
      state.loading = true;
      state.reviewed = false;
    });
    builder.addCase(giveReview.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reviewed= true;
      state.doctor_review = payload.data;

    });

    builder.addCase(giveReview.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default doctorSlice.reducer;
