import React, { useEffect, useState } from 'react';
import './register.css';
import { useForm } from 'react-hook-form';
import kenLogo from '../../assets/images/kenlogo.png';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';
import { formOptions } from './helper';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../store/Features/Auth/AuthApi';
import moment from 'moment/moment';
import ErrorText from '../../shared/ErrorText/ErrorText';
import { getLandingPageTopbardetails } from '../../store/Features/LandingPage/LandingPageApi';

const Regiser = () => {
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.AuthSlice);
  const landingPageState = useSelector((store) => store.LandingPageSlice);

  const { handleSubmit, register, formState } = useForm(formOptions);
  const { errors } = formState;

  const navigate = useNavigate();

  const onSubmit = (values) => {
    const formattedDate = moment(values.dob).format('MMMM D, YYYY');
    const formData = new FormData();
    formData.append('fullname', values?.fullname);
    formData.append('email', values?.email);
    formData.append('password', values?.password);
    formData.append('gender', values?.gender);
    formData.append('blood_group', values?.blood_group);
    formData.append('dob', formattedDate);
    formData.append('age', values?.age);
    formData.append('address', values?.address);
    formData.append('phone', values?.phone);
    dispatch(registerUser(formData));
  };

  const [eyeState, setEyeState] = useState(true);
  
  const toggleEye = () => {
    setEyeState(!eyeState);
  };
  useEffect(() => {
    if (authState?.is_success.singup) {
      navigate('/login');
    }
  }, [authState?.is_success.singup]);

  useEffect(()=>{
    dispatch(getLandingPageTopbardetails());

  },[])
  return (
    <div className="auth__wrapper register__form">
      <form className="register__form__width" onSubmit={handleSubmit(onSubmit)}>
        <div className="auth__header">
          <div className="half__logo" onClick={() => navigate('/')}>
            <img src={landingPageState?.landingPageTopBarDetails?.main_logo || kenLogo} alt="Logo" />
          </div>
          <h3>Register to Kencenter </h3>
          <p className="auth__text">
            Welcome ! Register to Kencenter and continue your journey.
          </p>
        </div>
        <div className="form__container ">
          <div className="left__section">
            <div className="form__row">
              <div className="input__group">
                <div className="group">
                  <label htmlFor="fullName">Fullname <span>*</span> </label>
                  <input
                    type="text"
                    name="fullname"
                    {...register('fullname')}
                    placeholder='Enter Fullname'
                    className={`${errors?.fullname && "is-invalid"}`}
                  />
                </div>
                {errors?.fullname && (
                  <ErrorText error={errors?.fullname?.message} />
                )}
                {/* <div className="error">Error</div> */}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="email">Email <span>*</span></label>
                  <input type="email" name="email" {...register('email')} 
                      className={`${errors?.email && "is-invalid"}`}placeholder='Enter Email' />
                </div>
                {errors?.email && <ErrorText error={errors?.email?.message} />}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="email">Date of Birth <span>*</span></label>
                  <input type="date" name="dob" {...register('dob')}     className={`${errors?.dob && "is-invalid"}`} />
                </div>
                {errors?.dob && <ErrorText error={errors?.dob?.message} />}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="password">Password <span>*</span></label>
                  <input
                    type={eyeState ? 'password' : 'text'}
                    name="password"
                    {...register('password')}
                    placeholder='Enter Password'
                    className={`${errors?.password && "is-invalid"}`}
                  />
                  <span
                    className="passwordToggle"
                    id="passwordToggle"
                    onClick={toggleEye}
                  >
                    {eyeState ? <Eye /> : <EyeOff />}
                  </span>
                </div>

                {errors?.password && (
                  <ErrorText error={errors?.password?.message} />
                )}
              </div>
            </div>
          </div>
          <div className="right__section">
            <div className="form__row">
              <div className="input__group">
                <div className="group">
                  <label htmlFor="phoneNumber">Phone Number <span>*</span></label>
                  <input type="tel" name="phone" {...register('phone')} placeholder='+977XXXXXXXXXX'    className={`${errors?.phone && "is-invalid"}`} />
                </div>
                {errors?.phone && (
                  <ErrorText error={errors?.phone?.message} />
                )}
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="address">Address <span>*</span></label>
                  <input type="text" name="address" {...register('address')} placeholder='Enter Address'     className={`${errors?.address && "is-invalid"}`} />
                </div>

                {errors?.address && (
                  <ErrorText error={errors?.address?.message} />
                )}
              </div>
              <div className="gender__age__wrapper">
                <div className="input__group">
                  <div className="group">
                    <label htmlFor="age">Age <span>*</span></label>
                    <input type="number" name="age" {...register('age')}placeholder='Enter Age'    className={`${errors?.age && "is-invalid"}`} />
                  </div>

                  {errors?.age && <ErrorText error={errors?.age?.message} />}
                </div>
                <div className="input__group">
                  <div className="group">
                    <label htmlFor="gender">Gender <span>*</span></label>
                    <select name="gender" {...register('gender')}>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Prefer not to say</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input__group">
                <div className="group">
                  <label htmlFor="blood_group">Blood Group <span>*</span></label>
                  <select name="blood_group" {...register('blood_group')}>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className={authState.is_loading.singup ? "disabled":""}>{authState.is_loading.singup ? <div className="spinner"></div> :"Register"}</button>
        
        <p className="dont__text">
          Already have an account?
          <span onClick={() => navigate('/login')}>Login now</span>
        </p>
      </form>
    </div>
  );
};

export default Regiser;
