const BASE_URL_FUNCTION = () => {
  // let url =window.location.hostname;
  // let url = "https://staging.kencenter.com.np/";
// console.log(url);
    if (process.env.NODE_ENV === "development") {
      // for development 
      // https://backend.kencenter.com.np/
      return `https://backend.kencenter.com.np/api`;
    }
    if (process.env.NODE_ENV === "production") {
        // for development
      // https://backend.kencenter.com.np/
      return `https://backend.kencenter.com.np/api`;
    }
  };
  
  export const BASE_URL = BASE_URL_FUNCTION();