import { createSlice } from '@reduxjs/toolkit';
import { changePassword, getUser, loginUser, logout, registerUser, resetPassword, sentPasswordResetEmail, updateProfile } from './AuthApi';
import LocalDb from '../../../helpers/LocalDB/LocalDb';

const initialState = {
  is_loading: {
    login: false,
    singup: false,
    logout: false,
    loading:false,
    load_user:false,
    update_user:false,
    reset_password:false
  },

  is_success: {
    login: false,
    singup: false,
    logout: false,
    loading:false,
    load_user:false,
    update_user:false,
    reset_password:false

  },
  is_authenticated: false,
  user: {},
};

const auhSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    falseLogoutState:(state,{payload})=>{
      state.is_success.logout = false;

    }
  },
  extraReducers: (builder) => {
    // SINGUP USER
    builder.addCase(registerUser.pending, (state) => {
      state.is_loading.singup = true;
      state.is_success.singup = false;
      state.is_loading.logout = false;
      state.is_success.logout = false;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.is_loading.singup = false;
      state.is_success.singup = true;
      state.is_loading.logout = false;
      state.is_success.logout = false;
    });
    builder.addCase(registerUser.rejected, (state) => {
      state.is_loading.singup = false;
      state.is_success.singup = false;
      state.is_loading.logout = false;
      state.is_success.logout = false;
    });

    // LOGIN USER
    builder.addCase(loginUser.pending, (state) => {
      state.is_loading.login = true;
      state.is_success.login = false;
      state.is_success.logout = false;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.is_loading.login = false;
      state.is_success.login = true;
      state.is_loading.logout = false;
      state.is_success.logout = false;
      state.is_authenticated = true;
      LocalDb.save('access-token', payload?.token);
      LocalDb.save('user', payload.data);
      state.user =  payload.data;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.is_loading.login = false;
      state.is_success.login = false;
      state.is_loading.logout = false;
      state.is_success.logout = false;
    });

    //GET USER DATA
    builder.addCase(getUser.pending, (state) => {
      state.is_loading.loading = true;
      state.is_success.load_user = false;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.is_loading.loading = false;
      state.is_success.load_user = true;
      state.user = payload.data;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.is_loading.loading = false;
      state.is_success.load_user =false;
    });
    
    // LOGOUT
    builder.addCase(logout.pending, (state) => {
      state.is_loading.logout = true;
      state.is_success.logout = false;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.is_loading.logout = false;
      state.is_success.logout = true;
      localStorage.removeItem("access-token");
      localStorage.removeItem('user');
      state.user = {};
      state.is_success.login = false;
      state.is_authenticated = false;
    });
    builder.addCase(logout.rejected, (state) => {
      state.is_loading.logout = false;
      state.is_success.logout = false;
    });

    //UPDATE  USER 
    builder.addCase(updateProfile.pending, (state) => {
      state.is_loading.update_user = true;
      state.is_success.update_user = false;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.is_loading.update_user = false;
      state.is_success.update_user = true;
      LocalDb.save('user', payload.data);
      // state.user = payload.data;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.is_loading.update_user = false;
      state.is_success.update_user =false;
    });

       //CHANGE   PASSWORD
       builder.addCase(changePassword.pending, (state) => {
        state.is_loading.loading = true;
      });
      builder.addCase(changePassword.fulfilled, (state, { payload }) => {
        state.is_loading.loading = false;

      });
      builder.addCase(changePassword.rejected, (state) => {
        state.is_loading.loading = false;
      });
         //RESET   PASSWORD
         builder.addCase(resetPassword.pending, (state) => {
          state.is_loading.reset_password = true;
          state.is_success.reset_password = false;
        });
        builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
          state.is_loading.reset_password = false;
          state.is_success.reset_password = true;
  
        });
        builder.addCase(resetPassword.rejected, (state) => {
          state.is_loading.reset_password = false;
          state.is_success.reset_password = false;
        });

         //SEND RESET   PASSWORD EMAIL
         builder.addCase(sentPasswordResetEmail.pending, (state) => {
          state.is_loading.loading = true;
          
        });
        builder.addCase(sentPasswordResetEmail.fulfilled, (state, { payload }) => {
          state.is_loading.loading = false;
  
        });
        builder.addCase(sentPasswordResetEmail.rejected, (state) => {
          state.is_loading.loading = false;
        });
  },
});

export default auhSlice.reducer;

export const {falseLogoutState} = auhSlice.actions;
