import React, { useEffect } from 'react';
import DoctorCard from './DoctorCard/DoctorCard';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDoctors } from '../../store/Features/Doctors/DoctorApi';
import Card from '../../shared/Card/Card';

const DoctorCards = () => {
  const dispath = useDispatch();
  const doctorState = useSelector((store) => store.DoctorSlice);

  useEffect(() => {
    dispath(getAllDoctors());
  }, [dispath]);


  return (
    <div className="dr-card-wrapper-section">
      <div className="dr-card-wrapper-div layout">
        {doctorState?.all_doctors && doctorState?.all_doctors?.length > 0 &&
          doctorState?.all_doctors?.map((doctor) => (
            // <DoctorCard key={doctor?.id} data={doctor} />
            <Card data={doctor}/>
          ))}
      </div>
      {doctorState?.all_doctors?.length === 0 && (
        <h3 className="dr-not-found">Doctors Not Found</h3>
      )}
    </div>
  );
};

export default DoctorCards;
