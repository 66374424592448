import React from 'react';
import parse from 'html-react-parser';
import "../../doctorDetails.css"
const Services = ({data}) => {
  return (
    <div className="desc-card">
      <h5 className="heading">Services</h5>
      {parse(`${data?.services || ""}`)}
    </div>
  );
};

export default Services;
