import React, { useEffect } from 'react';
import Dashboard from '../../components/Dashboard/Dashboard';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';

const DashboardPage = () => {
  return (
    <div>
      <Header />
      <Dashboard />
      <Footer />
    </div>
  );
};

export default DashboardPage;
