import { REQUIRED_VALIDATION } from '../../helpers/formUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
    full_name: yup.string().required(REQUIRED_VALIDATION('Fullname')) 
  .min(5, 'Fullname must be at least 5 characters long')
  .max(35, 'Fullname must not be exceed 35 characters long'),
  email: yup
    .string()
    .trim()
    .email('Invalid Email')
    .required(REQUIRED_VALIDATION('Email')),

    subject: yup.string().required(REQUIRED_VALIDATION('Subject')) 
    .min(10, 'Subject must be at least 10 characters long'),

    message: yup.string().required(REQUIRED_VALIDATION('message')) 
    .min(20, 'Fullname must be at least 10 characters long')

});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: 'onTouched',
  reValidateMode: 'onChange',
};
