import React, { useEffect } from 'react'
import Header from '../../components/Layout/Header/Header'
import Profile from '../../components/Profile/Profile'
import Footer from '../../components/Layout/Footer/Footer'
import LocalDb from '../../helpers/LocalDB/LocalDb'
import { useNavigate } from 'react-router-dom'

const ProfilePage = () => {
  const token = LocalDb.fetch('access-token');
  const user = LocalDb.fetch("user");
  const navigate = useNavigate();

  useEffect(()=>{
    if(!token && !user){
      navigate("/login")
    }
    window.scrollTo(0, 0);
  },[])
  return (
    <>
    <Header/>
    <Profile/>
    <Footer/>
      
    </>
  )
}

export default ProfilePage
