import React, { useEffect, useLayoutEffect, useState } from 'react';
import './allBlogs.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar, Tag, User } from 'react-feather';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCategories,
  getAllPosts,
  getAllTags,
  getBlogsByCategory,
  getBlogsBySlug,
  getBlogsByTag,
} from '../../../store/Features/BlogPosts/BlogPostsApi';
import moment from 'moment';

const AllBlogs = ({ isBlogDetails }) => {

  const [category, setCategory] = useState(false);
  const [tags, setTags] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const { slug } = params;


  const dispatch = useDispatch();

  const postsState = useSelector((store) => store.BlogPostsSlice);
  useLayoutEffect(() => {
    dispatch(getAllPosts(1));
    dispatch(getAllCategories());
    dispatch(getAllTags());
    
  }, []);

  useEffect(()=>{
    if(slug){
      dispatch(getBlogsBySlug(slug));
    }
  },[slug])

  const getBlogsByCategoryId = (category) => {
    setCategory(true);
    dispatch(getBlogsByCategory(category));
  };
  const getBlogsByTagsId = (tag) => {
    setTags(true);
    dispatch(getBlogsByTag(tag));
  };

  const handleTempPostRedirect = (post) => {
    setTags(false);
    setCategory(false);
    navigate(`/blog-details/${post?.slug}`);
  };
  const handlePrevPage = () => {
    const prevPage = postsState?.all_posts?.meta?.current_page - 1;
    localStorage.setItem("page",`${prevPage}`);
    dispatch(getAllPosts(prevPage));
  };
  const handleNextPage = () => {
    const nextPage = postsState?.all_posts?.meta?.current_page + 1;
    localStorage.setItem("page",`${nextPage}`);
    dispatch(getAllPosts(nextPage));
  };


  return (
    <>
      <div className="main">
        <div className="main__content layout">
          <div className="left">
            {isBlogDetails && postsState?.single_blog &&
postsState?.single_blog ? (
              !(category || tags) && (
                <div className="left__blog__content">
                  <div className="blog__detail__boxes">
                    <div className="blog__info__box">
                      <Calendar />
                      <span>
                        {moment(postsState?.single_blog?.published_date).format(
                          'DD MMMM,YYYY'
                        )}
                      </span>
                    </div>
                    <div className="blog__info__box">
                      <User />
                      <span>Ken Center</span>
                    </div>
                    <div className="blog__info__box">
                      <Tag />
                      <div className="tags__info__wrapper">
                        {postsState?.single_blog?.categories?.map((category) => (
                          <span key={category?.id} className="tag__span">
                            {category?.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <h2>{postsState?.single_blog?.title}</h2>
                  <div className="single__blog__image__wrapper">
                    <img src={postsState?.single_blog?.image} alt="" />
                  </div>
                  <div className="single__blog__mini__desc"><em>{postsState?.single_blog?.mini_desc}</em></div>
                  <div className="blog__all__content">
                    {parse(`${postsState?.single_blog?.description}`)}
                  </div>
                </div>
              )
            ) : (
              <div className="blog__single__cards">
                {postsState?.all_posts?.data &&
                  postsState?.all_posts?.data?.map((post) => (
                    <div
                      className="blog__single__card"
                      key={post?.id}
                      onClick={() => navigate(`/blog-details/${post?.slug}`)}
                    >
                      <div className="card__image">
                        <img
                          src={
                            post?.image ||
                            'https://i0.wp.com/picjumbo.com/wp-content/uploads/magical-spring-forest-scenery-during-morning-breeze-free-photo.jpg?w=600&quality=80'
                          }
                          alt="Blog Images"
                        />
                      </div>

                      <div className="title__description">
                        <p className="blog__title">{post?.title}</p>
                        <div className="tag__date">
                          <div className="posted__date">
                            {moment(post?.published_date).format('D MMMM YYYY')}
                          </div>
                          <div className="blog__tages">
                            {post?.categories?.map((category) => (
                              <div className="blog__tag">{category?.name}</div>
                            ))}
                          </div>
                        </div>
                        <p className="times">{post?.time}</p>

                        <p className="mini__desc">
                          {post?.mini_desc.substr(0, 400)}...
                        </p>

                        <div className="blog__author">
                          <span>
                            <User />
                          </span>
                          <span>Kencenter</span>
                        </div>
                      </div>
                    </div>
                  ))}
                {postsState?.posts?.data?.length === 0 && (
                  <h3 className="no__blog__posts">No Blog Posts Available</h3>
                )}
              </div>
            )}
            {(category || tags) && isBlogDetails && (
              <div className="blog__single__cards">
                {postsState?.temp_posts?.data?.map((post) => (
                  <div
                    className="blog__single__card"
                    key={post?.id}
                    onClick={() => handleTempPostRedirect(post)}
                  >
                    <div className="card__image">
                      <img
                        src={
                          post?.image ||
                          'https://i0.wp.com/picjumbo.com/wp-content/uploads/magical-spring-forest-scenery-during-morning-breeze-free-photo.jpg?w=600&quality=80'
                        }
                        alt=""
                      />
                    </div>

                    <div className="title__description">
                      <p className="blog__title">{post?.title}</p>
                      <div className="tag__date">
                        <div className="posted__date">
                          {moment(post?.published_date).format('D MMMM YYYY')}
                        </div>
                        <div className="blog__tages">
                          {post?.tags?.map((tag) => (
                            <div className="blog__tag">{tag?.tags}</div>
                          ))}
                        </div>
                      </div>
                      <p className="times">{post?.time}</p>

                      <p className="mini__desc">
                        {post?.mini_desc.substr(0, 400)}...
                      </p>

                      <div className="blog__author">
                        <span>
                          <User />
                        </span>
                        <span>Kencenter</span>
                      </div>
                    </div>
                  </div>
                ))}
                {postsState?.all_posts?.length === 0 && (
                  <h3 className="no__blog__posts">No Blog Posts Available</h3>
                )}
              </div>
            )}
            {
              !slug && postsState?.all_posts?.meta?.last_page > 1 && (  <div className="pagination__buttons">
              <div
                className={` common__paginate__btn ${
                  postsState?.all_posts?.meta?.current_page === 1
                    ? 'disable'
                    : ''
                }`}
                onClick={() => handlePrevPage()}
              >
                Prev
              </div>
              <div
                className={` common__paginate__btn ${
                  postsState?.all_posts?.meta?.current_page ===
                  postsState?.all_posts?.meta?.last_page
                    ? 'disable'
                    : ''
                }`}
                onClick={() => handleNextPage()}
              >
                Next
              </div>
            </div>)
            }
          
          </div>
          <div className="right">
            <div className="category box">
              <h3 className=" title">Blog Categories</h3>
              <div className="categories__container">
                {postsState?.categories?.map((category) => (
                  <div
                    className="single__category"
                    key={category?.id}
                    onClick={() => getBlogsByCategoryId(category?.id)}
                  >
                    {category?.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="tags box">
              <h3 className="title">Tags</h3>
              <div className="tags__container">
                {postsState?.tags?.map((tag) => (
                  <div
                    className="single__tag"
                    key={tag?.tags}
                    onClick={() => getBlogsByTagsId(tag?.id)}
                  >
                    {tag?.tags}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBlogs;
