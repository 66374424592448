import React from 'react';
import './doctorCard.css';
import { Award, CreditCard, Mail, MapPin, Star } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Rating from 'react-rating';

const DoctorCard = ({ hideRightSection, data ,widthHandle}) => {
  const navigate = useNavigate();
  return (
    <div className={`dr-card-wrapper ${widthHandle}`}>
      <div className="dr-left">
        <div className="dr-img-wrapper">
          <img src={data?.image} alt="" />
        </div>
        <div className="dr-info">
          <h3 className="name">{data?.doctor_name}</h3>
          <p className="worked-in">{data?.degree}</p>
          <p className="role designation">{data?.designation}</p>
          <div className="ratings">
            {/* @ts-ignore */}
            <Rating
              quiet
              readonly
              initialRating={!data?.star ? 0 : data?.star}
              emptySymbol={<Star color="#FFC600" />}
              fullSymbol={<Star color="#FFC600" fill="#FFC600" />}
            />

            <span>({data?.review_count})</span>
          </div>
          <div className="license box">
            <CreditCard />

            <span>NMC Regd. {data?.nmc_reg_no}</span>
          </div>
          <div className="license box">
            <Award />

            <span>{data?.years_of_experience}+ Years Experience</span>
          </div>
          <div className="license box">
            <MapPin />
            <span>{data?.address}</span>
          </div>
          <div className="license box">
            <Mail />
            <span>{data?.email}</span>
          </div>
          <div className="experties">
            <span>{data?.specialist}</span>
            {/* <span>Thyroids </span> */}
          </div>
        </div>
      </div>
      {!hideRightSection && (
        <div className="dr-right">
          <div className="common-box">
            <MapPin />
            <span>{data?.address}</span>
          </div>
          <div className="common-box">
            <Mail />
            <span>{data?.email}</span>
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={() => navigate(`/doctor-details/${data.id}`)}
              className="profile"
            >
              View Profile
            </button>
            <button
              type="button"
              className="book"
              onClick={() => navigate(`/doctor-details/${data.id}`)}
            >
              Book Appointment
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorCard;
