import React, { useCallback, useEffect, useState } from 'react';
import './album.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAlbums } from '../../store/Features/Gallery/GalleryApi';
import { useParams } from 'react-router-dom';
import Modal, { ModalBody, ModalHeader } from '../../shared/Modal/Modal';
import ImageViewer from 'react-simple-image-viewer';

const Album = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [image, setImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const galleryState = useSelector((store) => store.GallerySlice);

  useEffect(() => {
    dispatch(getAllAlbums());
  }, []);

  const { id } = params;

  const singleAlbum = galleryState?.albums?.filter(
    (item) => item?.id === parseInt(id)
  );

  const getSingleImage = (image) => {
    setShowModal(true);
    const singleImage = singleAlbum[0]?.images?.filter(
      (item) => item === image
    );
    setImage(singleImage);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div className="album__wrapper">
        <div className="album__container layout">
          <div className="album__content">
            <>
              <h3>{singleAlbum[0]?.album_name}</h3>
              <div className="album__images">
                {/* {singleAlbum[0]?.images?.map((image) => (
                  <div
                    className="album__image"
                    onClick={() => getSingleImage(image)}
                  >
                    <img src={image} alt="" />
                  </div>
                ))} */}
                {singleAlbum[0]?.images?.map((src, index) => (
                  <img
                    src={src}
                    onClick={() => openImageViewer(index)}
                    width="300"
                    key={index}
                    style={{ margin: '2px' }}
                    alt=""
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={singleAlbum[0]?.images}
                    currentIndex={currentImage}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                )}
              </div>
            </>
          </div>
        </div>
      </div>

      {/* <Modal show={showModal} setShow={setShowModal} onClick={closeModal} width={"handle__album__image__modal__width"}>
      <ModalBody modalWidth={"modal__width__handle"}>
        <div className="modal__image">
          <img src={image} alt="" />
        </div>
      </ModalBody>

    </Modal> */}
    </>
  );
};

export default Album;
