import React, { useEffect, useRef } from 'react'
import "./modal.css"
import { X } from 'react-feather';

const Modal = ({to,width,children,show,setShow,hideCloseButton,onClick}) => {
  const modalRef = useRef();
  const closeFrom = () => {
    setShow(false);
    onClick();
  };

  const handleClickOutSide = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
        setShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return ()=>{
      document.addEventListener("mousedown",handleClickOutSide);
    }
  }, []);
  return (
    <div className={`modal ${show ? "active" : ""}`} >
    <div className={`modal__content ${to} ${width} }`} ref={modalRef}>
      {!hideCloseButton && (
        <X onClick={closeFrom} className="modal__close" />
      )}
      {children}
    </div>
  </div>
  )
}

export default Modal

export const ModalHeader = ({title,options}) => {
    return (
      <div className="modal__header">
        {title} {options && options}
      </div>
    );
  };


  export const ModalBody = ({children,modalWidth}) => {
  
    return (
      <div  className={`modal__body ${modalWidth}`}>
        {children}
      </div>
    );
  };
  
