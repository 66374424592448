import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../apiConfigs/urlConfigs';
import axiosInstance from '../../../apiConfigs/axiosInstance';
import { TosatMessage } from '../../../helpers/Toast';

export const getAllDoctors = createAsyncThunk(
  'getAllDoctors',
  async (undefined, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/doctors`);
      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSpecificDoctor = createAsyncThunk(
    'getSpecificDoctor',
    async (id, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${BASE_URL}/doctor/${id}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );


  export const giveReview = createAsyncThunk(
    'giveReview',
    async (data, { rejectWithValue }) => {
      try {
        const res = await axiosInstance.post(`${BASE_URL}/review-doctor`,data);
        const d = {message:"Review added successfully",status:true}
        TosatMessage(d);
        return res.data;
      } catch (error) {
        TosatMessage(error.response.data);
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

