import React, { useEffect } from 'react'
import Header from '../../components/Layout/Header/Header'
import Footer from '../../components/Layout/Footer/Footer'
import DoctorCards from '../../components/DoctorCards/DoctorCards'

const DoctorsPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
    <Header/>
     <DoctorCards/>
      <Footer/>
    </>
  )
}

export default DoctorsPage
