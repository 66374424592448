import React from 'react';

import ReviewAddForm from './ReviewAddForm';
import Rating from 'react-rating';
import { Star } from 'react-feather';
import './review.css';
import { useDispatch, useSelector } from 'react-redux';

const Review = ({ data }) => {
  const dispatch = useDispatch();
  const reviewState = useSelector((store) => store.DoctorSlice);

  return (
    <div className="review-wrapper">
      <h5 className="review-heading">Doctor Review</h5>

      <div className="reviewer-cards">
        {reviewState?.doctor_review.length > 0 ? (
          reviewState?.doctor_review?.map((item) => (
            <div className="reviewer-card" key={item}>
              <div className="reviewer-img">
                {!item?.image ? (
                  <div className="user-first-avatar">
                    {item?.patient_name.charAt(0)}
                  </div>
                ) : (
                  <img src={item?.image} className="user-image" alt="" />
                )}
              </div>

              <div className="reviewer-rating-message">
                <div className="reviewer-name-star">
                  <h5 className="">{item?.patient_name}</h5>
                  <Rating
                    quiet
                    initialRating={item?.star}
                    readonly
                    emptySymbol={<Star size={14} color="#FFC600" />}
                    fullSymbol={
                      <Star size={14} color="#FFC600" fill="#FFC600" />
                    }
                  />
                </div>
                <p className="reviewer-message">{item?.review}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="be-first-reviwer">Be the First Reviewer</p>
        )}
      </div>

      <ReviewAddForm />
    </div>
  );
};

export default Review;
