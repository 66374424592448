/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './contact.css';
import { formOptions } from './helper';
import ErrorText from '../../shared/ErrorText/ErrorText';
import { useDispatch, useSelector } from 'react-redux';
import { contact, getLandingPageTopbardetails, getSocialMediaLinks } from '../../store/Features/LandingPage/LandingPageApi';
import {
  Facebook,
  Instagram,
  Linkedin,
  Mail,
  MapPin,
  Phone,
  Twitter,
  Youtube,
} from 'react-feather';

const Contact = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (values) => {
    dispatch(contact(values));
    reset();
  };
  const landingPageState = useSelector((store) => store.LandingPageSlice);
  const socialLinksState = useSelector((store)=>store.LandingPageSlice);

  useEffect(()=>{
    dispatch(getSocialMediaLinks());
    dispatch(getLandingPageTopbardetails());
  },[]);
  return (
    <div className="auth__wrapper contact__form ">
      <div className="cf__container layout">
        <div className="cf__left__section">
          <h3>Get In Touch</h3>
          <div className="fcc_icons social__icons" aria-label="Social links">
            <a href={socialLinksState?.socialMedialLinks?.facebook} role="link" aria-label="Facebook Icon" target='_blank' rel="noreferrer">
              <Facebook />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.instagram}role="link" aria-label="Instagram Icon" target='_blank' rel="noreferrer">
              <Instagram />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.linkedin}role="link" aria-label="Linkedn icon" target='_blank' rel="noreferrer">
              <Linkedin />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.twitter}role="link" aria-label="Twitter Icon" target='_blank' rel="noreferrer">
              <Twitter />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.youtube} role="link" aria-label="Youtube Icon" target='_blank' rel="noreferrer">
              <Youtube />
            </a>
            
          </div>

          <div className="contact__infos">
            <div className="cf__info">
              <div className="icon__wrapper">
                <Phone />
              </div>
              <span>+977 { landingPageState?.landingPageTopBarDetails?.phone}</span>
            </div>
            <div className="cf__info">
              <div className="icon__wrapper">
                <MapPin />
              </div>
              <span>{landingPageState?.landingPageTopBarDetails?.address}</span>
            </div>
            <a className="cf__info" href={`mailto:${landingPageState?.landingPageTopBarDetails?.email}`}>
              <div className="icon__wrapper">
                <Mail />
              </div>
              <span>{landingPageState?.landingPageTopBarDetails?.email}</span>
            </a>
          </div>
        </div>
        <form
          className="register__form__width  "
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form__row handle__margin__form__row">
            <div className="input__group">
              <div className="group">
                <label htmlFor="fullName">
                  Fullname <span>*</span>
                </label>
                <input
                  type="text"
                  name="full_name"
                  {...register('full_name')}
                />
              </div>
              {errors?.full_name && (
                <ErrorText error={errors?.full_name?.message} />
              )}
            </div>
            <div className="input__group">
              <div className="group">
                <label htmlFor="email">
                  Email <span>*</span>
                </label>
                <input type="email" name="email" {...register('email')} />
              </div>
              {errors?.email && <ErrorText error={errors?.email?.message} />}
            </div>
            <div className="input__group">
              <div className="group">
                <label htmlFor="subject">
                  Subject <span>*</span>
                </label>
                <input type="text" name="subject" {...register('subject')} />
              </div>
              {errors?.subject && (
                <ErrorText error={errors?.subject?.message} />
              )}
            </div>
            <div className="input__group">
              <div className="group">
                <label htmlFor="message">
                  Message <span>*</span>
                </label>
                <textarea
                  name="message"
                  className="contact__textarea"
                  {...register('message')}
                ></textarea>
              </div>
              {errors?.message && (
                <ErrorText error={errors?.message?.message} />
              )}
            </div>
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
