import React from 'react'
import Header from '../../components/Layout/Header/Header'
import ChangePassword from '../../components/ChangePassword/ChangePassword'
import Footer from '../../components/Layout/Footer/Footer'

const ChangePasswordPage = () => {
  return (
    <div>
      <Header/>
      <ChangePassword/>
      <Footer/>
    </div>
  )
}

export default ChangePasswordPage
