import { createSlice } from '@reduxjs/toolkit';
import { getSeoDetails } from './SeoApi';


const initialState = {
    loading:false,
    seo_data:null,
 
};

const SeoSlice = createSlice({
  name: 'SeoSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET HEADER TOPBAR DETAILS
    builder.addCase(getSeoDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSeoDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.seo_data = action.payload.data;
    });
    builder.addCase(getSeoDetails.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default SeoSlice.reducer;
