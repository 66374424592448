import React, { useEffect, useState } from 'react';
import kenLogo from '../../assets/images/kenlogo.png';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorText from '../../shared/ErrorText/ErrorText';
import { sentPasswordResetEmail } from '../../store/Features/Auth/AuthApi';
import { getLandingPageTopbardetails } from '../../store/Features/LandingPage/LandingPageApi';

const ForgotPassword = () => {

  const { handleSubmit, register, formState } = useForm();
  const { errors } = formState;
  const dispatch = useDispatch();
  const authState = useSelector((store)=>store.AuthSlice);
  const landingPageState = useSelector((store) => store.LandingPageSlice);

  const navigate = useNavigate();

  const onSubmit = (values) => {
    dispatch(sentPasswordResetEmail(values));
  };

  
  useEffect(()=>{
    dispatch(getLandingPageTopbardetails());

  },[])

  return (
    <div className="auth__wrapper">
      <form className="auth__container" onSubmit={handleSubmit(onSubmit)}>
        <div className="auth__header">
          <div className="half__logo" onClick={() => navigate('/')}>
            <img src={landingPageState?.landingPageTopBarDetails?.main_logo || kenLogo} alt="Logo" />
          </div>
          <h3>Choose email address</h3>
        </div>
        <div className="form__row">
          <div className="input__group">
            <div className="group">
              <label htmlFor="email">Send recovery link to</label>
              <input
                type="email"
                name="email"
                className={`${errors?.email && 'is-invalid'}`}
                {...register('email')}
                placeholder="Enter email address"
              />
            </div>
            {errors?.email && <ErrorText error={errors?.email?.message} />}
          </div>
        </div>

        <button type="submit" className={authState.is_loading.loading ? "disabled":""}>
          {authState.is_loading.loading ? <div className="spinner"></div> :"Send"}
          
          </button>
        <p className="dont__text">
          <span onClick={() => navigate('/login')}>Return to login</span>
        </p>
      </form>
    </div>
  );
};

export default ForgotPassword;
