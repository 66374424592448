import React from 'react'
import "./index.css"
import notFoundImg from "../../assets/images/not-found.png";
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

  return (
    <div className='notfound__wrapper'>
        <div className="not__found__img">
            <img src={notFoundImg} alt="" />
        </div>
        <p className="page__notfound__text">The page you requested couldn't found.</p>
        <div className="goto__home__btn" >
            <button type='button' onClick={()=>navigate("/")}>Go To Home</button>
        </div>
      
    </div>
  )
}

export default NotFound
