import React from 'react'
import "./errorText.css"

const ErrorText = ({error}) => {
  return (
    <div className="error">{error}</div>
  )
}

export default ErrorText
