import { REQUIRED_VALIDATION } from "../../helpers/formUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Invalid Email")
      .required(REQUIRED_VALIDATION("Email")),
    password: yup.string().trim().required(REQUIRED_VALIDATION("Password"))
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
    // ),
  });


 export const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };