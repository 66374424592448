import React, { useEffect, useRef, useState } from 'react';

import './header.css';
import './topbar.css';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
  Book,
  ChevronUp,
  Clock,
  LogOut,
  Mail,
  MapPin,
  Menu,
  Phone,
  User,
  X,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLandingPageTopbardetails,
  getNotices,
} from '../../../store/Features/LandingPage/LandingPageApi';
import { getUser, logout } from '../../../store/Features/Auth/AuthApi';
import LocalDb from '../../../helpers/LocalDB/LocalDb';

const Header = () => {
  const token = LocalDb.fetch('access-token');
  const user = LocalDb.fetch('user');

  const [showTopUp, setShowTopUp] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showSidebar, setShwoSidebar] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const dispatch = useDispatch();
  const landingPageState = useSelector((store) => store.LandingPageSlice);
  const authPageState = useSelector((store) => store.AuthSlice);

  const profileAvatarRef = useRef(null);
  const dropdownRef = useRef(null);

  const sidbarRef = useRef(null);

  const navigateToLogingPage = () => {
    navigate('/login');
  };
  const handleToggleDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const scrolltoTop = () => {
    window.scrollTo(0, 0);
  };
  const changeNavBg = () => {
    if (window.scrollY > 1) {
      setShowTopUp(true);
    } else {
      setShowTopUp(false);
    }
  };
  window.addEventListener('scroll', changeNavBg);

  useEffect(() => {
    dispatch(getLandingPageTopbardetails());
    dispatch(getNotices());
  }, []);

  useEffect(() => {
    if (authPageState?.is_success.logout && !authPageState?.is_loading.logout) {
      navigate('/login');
      setShwoSidebar(false);
    }
  }, [authPageState]);
  useEffect(() => {
    if (
      !authPageState?.is_loading?.update_user &&
      authPageState?.is_success.update_user
    ) {
      dispatch(getUser());
    }
  }, [
    authPageState?.is_loading?.update_user,
    authPageState?.is_success.update_user,
    dispatch,
  ]);

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    }
  }, [token]);

  const handleClickOutSide = (event) => {
    if (
      profileAvatarRef.current &&
      !profileAvatarRef.current.contains(event.target)
    ) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowProfileDropdown(false);
      }
    }
    if(sidbarRef.current && !sidbarRef.current.contains(event.target)){
      setShwoSidebar(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.addEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  return (
    <header className="header header-wrapper">
      <div className={`topbar-wrapper`}>
        <div className="topbar-container ">
          <div className={`topbar-content layout`}>
            <div className="topbar-left-section">
              <ul className="top-link">
                <li>
                  <Clock />
                  <span>
                    {landingPageState?.landingPageTopBarDetails &&
                    landingPageState?.landingPageTopBarDetails
                      ? landingPageState?.landingPageTopBarDetails
                          ?.business_hours
                      : ''}
                  </span>
                </li>
                <li>
                  <MapPin />
                  <span>
                    {landingPageState?.landingPageTopBarDetails &&
                    landingPageState?.landingPageTopBarDetails
                      ? landingPageState?.landingPageTopBarDetails?.address
                      : ''}
                  </span>
                </li>
              </ul>
            </div>
            <div className="topbar-right-section">
              <ul className="top-contact">
                <li>
                  <Phone />
                  <span>
                    {landingPageState?.landingPageTopBarDetails &&
                    landingPageState?.landingPageTopBarDetails
                      ? landingPageState?.landingPageTopBarDetails?.phone
                      : ''}
                  </span>
                </li>
                <li>
                  <Mail />
                  <a href="mailto:kencenter@gmail.com">
                    {landingPageState?.landingPageTopBarDetails &&
                    landingPageState?.landingPageTopBarDetails
                      ? landingPageState?.landingPageTopBarDetails?.email
                      : ''}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="header-inner">
        <div className="container container-wrapper">
          <div className="inner">
            <div className="sm-responsive-header layout ">
              <div className=" sm-header-logo">
                <div className={`logo`}>
                  {landingPageState?.landingPageTopBarDetails?.main_logo && (
                    <Link to="/">
                      <img
                        src={
                          landingPageState?.landingPageTopBarDetails?.main_logo
                        }
                        alt="#"
                        className="lg-logo"
                      />
                    </Link>
                  )}
                </div>
              </div>
              <div
                className={` ${
                  showSidebar ? 'main-menu-wrapper' : 'handle-main-menu-wrapper'
                } main-menu `}
                ref={sidbarRef}
              >
                <div className="logo-and-cross-btn">
                  <div className="show-in-sm">
                    <div className={`logo`}>
                      {landingPageState?.landingPageTopBarDetails
                        ?.main_logo && (
                        <Link to="/">
                          <img
                            src={
                              landingPageState?.landingPageTopBarDetails
                                ?.main_logo
                            }
                            alt="#"
                            className="sm-logo"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    className="close-navbar"
                    onClick={() => setShwoSidebar(false)}
                  >
                    <X />
                  </div>
                </div>

                <div className="main-menu ">
                  <nav className="navigation">
                    <ul className="menu">
                      <li
                        className={location?.pathname === '/' ? 'active' : ''}
                      >
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className={
                          location?.pathname === '/doctors' ? 'active' : ''
                        }
                      >
                        <Link to="/doctors">Doctors </Link>
                      </li>
                      <li
                        className={
                          location?.pathname === '/gallery' ? 'active' : ''
                        }
                      >
                        <Link to="/gallery">Gallery </Link>
                      </li>

                      <li
                        className={
                          location?.pathname === '/blogs' ? 'active' : ''
                        }
                      >
                        <Link to="/blogs">Blogs</Link>
                      </li>

                      <li
                        className={
                          location?.pathname === '/contact' ? 'active' : ''
                        }
                      >
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {!token ? (
                  <button
                    className="login-register-btn"
                    onClick={() => navigateToLogingPage()}
                    ref={profileAvatarRef}
                  >
                    <User />
                    <span className="d-md-inline">Login<span className='hide-register-in-sm'>/Register</span> </span>
                  </button>
                ) : (
                  <>
                    <button
                      className={`user-toggle-btn hide-in-sm-device ${
                        authPageState?.user?.image ? 'hover-image-disable' : ''
                      }`}
                      onClick={() => handleToggleDropdown()}
                    >
                      {!authPageState?.user?.image ? (
                        <div className="user-avatar">
                          {user?.fullname.charAt(0)}
                        </div>
                      ) : (
                        <div className="user-image-avatar">
                          <img src={user?.image} alt="" />
                        </div>
                      )}

                      {showProfileDropdown && (
                        <div className="user-action-dropdown" ref={dropdownRef}>
                          <span onClick={() => navigate('/profile')}>
                            <User />
                            Profile
                          </span>
                          <span onClick={() => navigate('/dashboard')}>
                            <Book />
                            Dashboard
                          </span>
                          <span onClick={handleLogout}>
                            <LogOut />
                            Logout
                          </span>
                        </div>
                      )}
                    </button>

                    <button
                      className=" logout-btn"
                      onClick={() => handleLogout()}
                    >
                      <LogOut />
                      <span>Logout</span>
                    </button>
                  </>
                )}
              </div>
              <div className="hamburger-icon">
                {!token ? null : (
                  <button
                    className="user-toggle-btn"
                    onClick={() => handleToggleDropdown()}
                  >
                    {!authPageState?.user?.image ? (
                      <div className="user-avatar">
                        {user?.fullname.charAt(0)}
                      </div>
                    ) : (
                      <div className="user-image-avatar">
                        <img src={user?.image} alt="" />
                      </div>
                    )}

                    {showProfileDropdown && (
                      <div
                        className="user-action-dropdown"
                        ref={profileAvatarRef}
                      >
                        <span onClick={() => navigate('/profile')}>
                          <User />
                          Profile
                        </span>
                        <span onClick={() => navigate('/dashboard')}>
                          <Book />
                          Dashboard
                        </span>
                        <span onClick={handleLogout}>
                          <LogOut />
                          Logout
                        </span>
                      </div>
                    )}
                  </button>
                )}

                <div onClick={() => setShwoSidebar(!showSidebar)}>
                  {<Menu />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showTopUp && (
        <button type="button" className="topup" onClick={() => scrolltoTop()}>
          <span>
            <ChevronUp />
          </span>
        </button>
      )}
    </header>
  );
};

export default Header;
