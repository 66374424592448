import React, { useEffect } from 'react'
import Header from '../../components/Layout/Header/Header'
import Contact from '../../components/Contact/Contact'
import Footer from '../../components/Layout/Footer/Footer'

const ContactPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
    <Header/>
    <Contact/>
    <Footer/>
      
    </>
  )
}

export default ContactPage
