import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../apiConfigs/axiosInstance";
import { BASE_URL } from "../../../apiConfigs/urlConfigs";
import { TosatMessage } from "../../../helpers/Toast";

export const getBookedAppointments = createAsyncThunk(
    'getBookedAppointments',
    async (pageNumber, { rejectWithValue }) => {
      try {
        const res = await axiosInstance.get(`${BASE_URL}/view-booking?page=${pageNumber}`);
        return res.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );


  
  export const bookAppointment = createAsyncThunk(
    'bookAppointment',
    async (data, { rejectWithValue }) => {
      try {
        const res = await axiosInstance.post(`${BASE_URL}/book-appointment`,data);
        TosatMessage(res.data.data);  
        return res.data;
      } catch (error) {
        // const data = {message:"Please Select a slot to book an appointment",status:false}
        TosatMessage(error.response.data);
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );