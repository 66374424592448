import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_VALIDATION } from '../../../helpers/formUtils';

const validationSchema = yup.object().shape({
    old_password: yup
    .string()
    .trim()
    .required(REQUIRED_VALIDATION('Old Password'))
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
   
    new_password: yup
    .string()
    .trim()
    .required(REQUIRED_VALIDATION('New Password'))
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
    new_password_confirmation: yup
    .string()
    .trim()
    .required(REQUIRED_VALIDATION("Confirm Password"))
    .oneOf([yup.ref("new_password"), null], "New Password and Confirm Password does not match"),

});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: 'onTouched',
  reValidateMode: 'onChange',
};
