
import "./statusLabel.css"
const ConfirmLabel = ()=>{
    return(
        <div className="confirm">Confirmed</div>
    )
}

const CancelledLabel = ()=>{
    return(
        <div className="cancel">Cancelled</div>
    )
}

const PendingLabel = ()=>{
    return(
        <div className="pending">Pending</div>
    )
}

export {ConfirmLabel,CancelledLabel,PendingLabel}
