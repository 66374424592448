import React, { useEffect } from 'react'
import "./changePassword.css"
import ProfileLeftDash from '../Profile/ProfieLeftDassh/ProfileLeftDash'
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../store/Features/Auth/AuthApi'

const ChangePassword = () => {
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.AuthSlice);
  useEffect(() => {
    dispatch(getUser());
  }, []);
  useEffect(() => {
    if(!authState?.is_loading?.update_user && authState?.is_success.update_user){
      dispatch(getUser());
    }
  }, [authState?.is_loading?.update_user,authState?.is_success.update_user,dispatch]);
  return (
    <div className="profile__dash__wrapper">
    <div className="profile__dash__container layout">
    <ProfileLeftDash authState={authState} />
      <ChangePasswordForm/>
    </div>
  </div>
  )
}

export default ChangePassword
