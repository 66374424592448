import React, { lazy, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Header from '../../components/Layout/Header/Header';
// import Footer from '../../components/Layout/Footer/Footer';
// import Process from '../../components/Process/Process';
// import ChooseUs from '../../components/ChooseUs/ChooseUs';
// import Category from '../../components/Category/Category';
// import Testimonial from '../../components/Testimonial/Testimonial';
// import Faq from '../../components/Faq/Faq';
// import HeroSection from '../../components/HeroSection/HeroSection';
// import DoctorSlider from '../../components/DoctorSlider/DoctorSlider';
// import Blog from '../../components/AllBlogs/Blog';
import { useDispatch, useSelector } from 'react-redux';
import { getSeoDetails } from '../../store/Features/Seo/SeoApi';
import { Helmet } from 'react-helmet';
const Header = lazy(() => import('../../components/Layout/Header/Header'));
const Footer = lazy(() => import('../../components/Layout/Footer/Footer'));
const HeroSection = lazy(() => import('../../components/HeroSection/HeroSection'));
const ChooseUs = lazy(() => import('../../components/ChooseUs/ChooseUs'));
const Process = lazy(() => import('../../components/Process/Process'));
const Category = lazy(() => import('../../components/Category/Category'));
const Testimonial = lazy(() => import('../../components/Testimonial/Testimonial'));
const Faq = lazy(() => import('../../components/Faq/Faq'));
const DoctorSlider = lazy(() => import('../../components/DoctorSlider/DoctorSlider'));
const Blog = lazy(() => import('../../components/AllBlogs/Blog'));
const HomePage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, delay: 200 });
  }, []);

  const dispatch = useDispatch();
  const seoState = useSelector((store) => store?.SeoSlice);

  useEffect(() => {
    dispatch(getSeoDetails()); // Assuming you pass the page type or identifier
  }, []);

  return (
    <>
      <Helmet>
        <title>{seoState?.seo_data?.meta_title}</title>
        <meta
          name="description"
          content={seoState?.seo_data?.meta_description}
        />
          <meta name="keywords"
            content={seoState?.seo_data?.meta_keywords}></meta>
      </Helmet>
      <Header />
      <HeroSection />
      <Process />
      <ChooseUs />
      <Category />
      <DoctorSlider />
      <Blog />
      <Testimonial />
      <Faq />
      <Footer />
    </>
  );
};

export default HomePage;
