import { createSlice } from '@reduxjs/toolkit';
import { bookAppointment, getBookedAppointments } from './AppointmentApi';

const initialState = {
  is_loading:{
    loading:false,
    get:false,

  },
  is_success:{
    loading:false,
    get:false
  },
  booked_appointments: [],
  temp_booked_apointments:[]
};
const appointmentSlice = createSlice({
  name: 'appointmentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET USER DATA
    builder.addCase(getBookedAppointments.pending, (state) => {
      state.is_loading.get = true;
      state.is_success.get = false;
      state.is_loading.loading = false;
      state.is_success.loading = false;
    });
    builder.addCase(getBookedAppointments.fulfilled, (state, { payload }) => {
      state.is_loading.get = false;
      state.is_success.get = true;
      state.is_loading.loading = false;
      state.is_success.loading = false;
      state.temp_booked_apointments = payload;

      if(state.temp_booked_apointments?.meta?.current_page === 1 ){
        state.booked_appointments = state.temp_booked_apointments?.data;

      }

      else if (state.temp_booked_apointments?.meta?.current_page > 1  && state.temp_booked_apointments?.meta?.current_page <= state.temp_booked_apointments?.meta?.last_page){
        state.booked_appointments = state.temp_booked_apointments.data;
      }
      
    });
    builder.addCase(getBookedAppointments.rejected, (state) => {
      state.is_loading.loading = false;
      state.is_success.loading = false;
      state.is_loading.get = false;
      state.is_success.get = false;
    });

    // Book Appointment
    builder.addCase(bookAppointment.pending, (state, { payload }) => {
      state.is_loading.loading = true;
      state.is_success.loading = false;
    });
    builder.addCase(bookAppointment.fulfilled, (state, { payload }) => {
      state.is_loading.loading = false;
      state.is_success.loading = true;
    });

    builder.addCase(bookAppointment.rejected, (state, action) => {
      state.is_loading.loading = false;
      state.is_success.loading = false;
    });
  },
});

export default appointmentSlice.reducer;
