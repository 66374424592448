import React, { useEffect } from 'react';
import Gallery from '../../components/Gallery/Gallery';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';

const GalleryPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
      <Header />
      <Gallery />
      <Footer />
    </>
  );
};

export default GalleryPage;
