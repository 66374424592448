import React, { useEffect } from 'react'
import Header from '../../components/Layout/Header/Header'
import Album from '../../components/Albums/Album'
import Footer from '../../components/Layout/Footer/Footer'

const AlbumsPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
    <Header/>
    <Album/>
    <Footer/>
      
    </>
  )
}

export default AlbumsPage
