import React, { useEffect, useState } from 'react';
import { Eye, Printer } from 'react-feather';
import './bookAppointment.css';
import {
  CancelledLabel,
  ConfirmLabel,
  PendingLabel,
} from '../../../shared/StatusLabels/StatusLabel';
import { useDispatch, useSelector } from 'react-redux';
import { getBookedAppointments } from '../../../store/Features/Appointment/AppointmentApi';
import AppointMentpdf from './AppointmentPdf/AppointMentpdf';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const BookedAppointMentTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appointmentState = useSelector((store) => store.AppointmentSlice);
  const [activePage, setActivePage] = useState(1);

  const handleViewAppointmentDetails = (appointmentId) => {
    navigate(`/view-appointment-details/${appointmentId}`);
  };

  useEffect(() => {
    dispatch(getBookedAppointments(1));
  }, [dispatch]);

  const getperPageData = (page) => {
    setActivePage(page);
    dispatch(getBookedAppointments(page));
  };
  let pageCount = Math.ceil(
    appointmentState?.temp_booked_apointments?.meta?.total /
      appointmentState?.temp_booked_apointments?.meta?.per_page
  );

  // last_page


  return (
    <div className="table__wrapper">
      <div className="table__container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Appt Date</th>
              <th>Booking Date</th>
              <th>Status</th>
              {/* <th className="hide">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {appointmentState?.booked_appointments.length > 0 &&
              appointmentState?.booked_appointments.map((item) => (
                <tr className="" key={item}>
                  <td>#{item?.id}</td>
                  <td className="name__td">
                    <div className="img__wrapper">
                      <img
                        src={
                          item?.doctor_image ||
                          'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHx8MA%3D%3D'
                        }
                        alt=""
                      />
                    </div>
                    <span>{item?.doctor_name}</span>
                  </td>

                  <td className="appontment__date__td">
                    <div className="date">{moment(item?.date).format("DD MMMM YYYY")}</div>
                    <div className="time">{item?.slot?.start}</div>
                  </td>
                  <td className="booking__date__td">
                    {' '}
                    {moment(item?.created_at).format("DD MMMM YYYY")}
                  </td>
                  <td>
                    {item?.status === 'confirmed' && <ConfirmLabel />}
                    {item?.status === 'canceled' && <CancelledLabel />}
                    {item?.status === 'pending' && <PendingLabel />}
                  </td>

                  {/* <td className="action__wrapper ">
                  <div className="action__container">
                    {' '}
                    <div className="acton__box print">
                      <Printer className="edit__icon" />
                      <span>Print</span>
                    </div>
                    <div className="acton__box view"
                    //  onClick={()=>handleViewAppointmentDetails(item?.id)}
                     >
                      <Eye className="edit__icon" />
                      <span>View</span>
                    </div>
                  </div>
                </td> */}
                </tr>
              ))}
          </tbody>
        </table>
        {appointmentState?.booked_appointments.length === 0 && (
          <p className="not__booked__text">No Appointments Booked Yet !  </p>
        )}

        {appointmentState?.temp_booked_apointments?.meta?.last_page > 1 ? (
          <div className="pagenation__wrapper">
            <div className="pagenations">
              {Array.from({ length: pageCount }, (_, index) => (
                <button
                  className={`pagenation__circle ${
                    activePage === index + 1 ? 'active' : ''
                  }`}
                  onClick={() => getperPageData(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
         ) : null} 
      </div>
      {/* <AppointMentpdf/> */}
    </div>
  );
};

export default BookedAppointMentTable;
