import React from 'react'
import "./footerBottom.css";
import { useNavigate } from 'react-router-dom';

const FooterBottom = ({socialLinksState}) => {
    const navigate = useNavigate();
  return (
    <div className='foot__bottom__wrapper' >
        <div className="foot__bottom__container">
            <div className="foot__bottom__content layout">
                <div className="foot__bottom__left">
                    <p>© {new Date().getFullYear()} Ken Center | Developed By: <a href="https://mauntechnology.com" target="_blank" rel="noreferrer" >Maun Technology Pvt. Ltd.</a></p>
                </div>
                <div className="foot__bottom__right">
                    {/* <div className="social__icons">
                       <a  href={socialLinksState?.socialMedialLinks?.facebook} target='_blank' rel="noreferrer">
                            <Facebook/>
                       </a>
                       <a href={socialLinksState?.socialMedialLinks?.instagram} target='_blank' rel="noreferrer">
                            <Instagram/>
                       </a>
                       <a href={socialLinksState?.socialMedialLinks?.linkedin} target='_blank' rel="noreferrer">
                            <Linkedin/>
                       </a>
                       <a href={socialLinksState?.socialMedialLinks?.twitter} target='_blank' rel="noreferrer">
                            <Twitter/>
                       </a>
                       <a href={socialLinksState?.socialMedialLinks?.youtube} target='_blank' rel="noreferrer">
                            <Youtube/>
                       </a>
                        
                    </div> */}
                    <div className="foot__bottom__sublinks">
                        <p onClick={()=>navigate("/doctors")}>Appointment</p>
                        <div className="bar"></div>
                        <p onClick={()=>navigate("/")}>Homepage</p>
                        <div className="bar"></div>
                        <p onClick={()=>navigate("/contact")}>Contact</p>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default FooterBottom
