import React, { useEffect } from 'react'
import Header from '../../components/Layout/Header/Header'
import Footer from '../../components/Layout/Footer/Footer'
import AllBlogs from '../../components/AllBlogs/AllBlogs/AllBlogs'
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const BlogDetailsPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

const postsState = useSelector((store) => store.BlogPostsSlice);
  return (
    <>
         <Helmet>
        <title>{postsState?.single_blog?.meta_title}</title>
        <meta
          name="description"
          content={postsState?.single_blog?.meta_description}
        />
          <meta name="keywords"
            content={postsState?.single_blog?.meta_keywords}></meta>
      </Helmet>
    <Header/>
    <AllBlogs isBlogDetails={true}/>
    <Footer/>
      
    </>
  )
}

export default BlogDetailsPage
