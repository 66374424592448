import React, { useEffect } from 'react';
import './gallery.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllAlbums,
  getAllVideos,
} from '../../store/Features/Gallery/GalleryApi';
import { Link, useNavigate } from 'react-router-dom';
import getYoutubeVideoId from '../../helpers/getYouttubeVideoId';
import { ArrowRight } from 'react-feather';

const Gallery = () => {
  const dispath = useDispatch();
  const galleryState = useSelector((store) => store.GallerySlice);
  const navigate = useNavigate();

  useEffect(() => {
    dispath(getAllAlbums(1));
    dispath(getAllVideos(1));
  }, []);

  const loadMoreVideos = () => {
    const nextPage = galleryState?.all_video_links?.meta?.current_page + 1;
    dispath(getAllVideos(nextPage));
  };

  const loadMoreAlbum = () => {
    const nextPage = galleryState?.all_album?.meta?.current_page + 1;
    dispath(getAllAlbums(nextPage));
  };

  return (
    <div className="gallery__wrapper">
      <div className="gallery__content__container layout">
        <div className="gallery__content">
          <h3>Our Gallery</h3>
          <div className="album__boxes">
            {galleryState?.albums?.map((album) => (
              <div className="album__name__wrapper">
                <Link
                  to={`/album/${album?.id}`}
                  key={album?.id}
                  className="album"
                >
                  <img
                    src={
                      album?.cover_image ||
                      'https://media.istockphoto.com/id/517188688/photo/mountain-landscape.jpg?s=612x612&w=0&k=20&c=A63koPKaCyIwQWOTFBRWXj_PwCrR4cEoOw2S9Q7yVl8='
                    }
                    alt=""
                  />
                </Link>
                <div
                  onClick={() => navigate(`/album/${album?.id}`)}
                  className="overlay"
                >
                  <p className="albun__name">{album?.album_name}(Album) </p>
                  <ArrowRight/>
                </div>
              </div>
            ))}
            {galleryState?.albums?.length === 0 && (
              <p className="no__album__text">No Album is available</p>
            )}
          </div>
          {galleryState?.all_album?.meta?.total >
            galleryState?.all_album?.meta?.per_page && (
            <div className="loadMore__section">
              <div className="loadmore__button">
                <button
                  onClick={loadMoreAlbum}
                  type="button"
                  disabled={
                    galleryState?.all_album?.meta?.current_page ===
                    galleryState?.all_album?.meta?.last_page
                  }
                >
                  {galleryState?.album_loading ? 'Loading...' : '  Load More'}
                </button>
              </div>
            </div>
          )}

          <h3>Our Videos</h3>
          <div className="album__boxes">
            {galleryState?.all_videos?.map((item) => (
              <div
                key={item?.id}
                style={{ position: 'relative', paddingTop: '56.25%' }}
              >
                <iframe
                  title={item?.title}
                  src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                    item?.url
                  )}`}
                  loading="lazy"
                  style={{
                    border: 'none',
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    width: '100%',
                    borderRadius: '5px',
                  }}
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
          {galleryState?.all_videos?.length === 0 && (
            <p className="no__videos__text">No Videos Available</p>
          )}
          {galleryState?.all_video_links?.meta?.total >
            galleryState?.all_video_links?.meta?.per_page && (
            <div className="loadMore__section">
              <div className="loadmore__button">
                <button
                  onClick={loadMoreVideos}
                  type="button"
                  disabled={
                    galleryState?.all_video_links?.meta?.current_page ===
                    galleryState?.all_video_links?.meta?.last_page
                  }
                >
                  {galleryState?.loading ? 'Loading...' : '  Load More'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
