import React, { useEffect } from 'react'
import Header from  "../../components/Layout/Header/Header.jsx"
import Footer from '../../components/Layout/Footer/Footer'
import AllBlogs from '../../components/AllBlogs/AllBlogs/AllBlogs.jsx'

const Blog = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
    <Header/>
    <AllBlogs/>
    <Footer/>
    </>
  )
}

export default Blog
