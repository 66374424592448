import React, { useEffect, useRef, useState } from 'react';
import DoctorCard from '../DoctorCard/DoctorCard';
import './doctorDetails.css';

import AboutUs from '../DoctorDetails/DetailsTab/AboutUs/AboutUs';
import Services from '../DoctorDetails/DetailsTab/Services/Services';
import HealthCare from '../DoctorDetails/DetailsTab/HealthCare/HealthCare';
import Review from '../DoctorDetails/DetailsTab/Review/Review';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecificDoctor } from '../../../store/Features/Doctors/DoctorApi';
import LocalDb from '../../../helpers/LocalDB/LocalDb';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import {
  bookAppointment,
  getBookedAppointments,
} from '../../../store/Features/Appointment/AppointmentApi';

const DoctorDetails = () => {
  const token = LocalDb.fetch('access-token');
  const today = new Date().toISOString().split('T')[0];

  const [currentTab, setCurrentTab] = useState(1);

  const [startDate, setStartDate] = useState(today);

  const [doctorSchedule, setDoctorSchedule] = useState([]);

  const [time, setTime] = useState(null);

  const [currentValue, setCurrentValue] = useState(-1);
  const [upcomingDates, setUpcomingDates] = useState([]);
  const [currIndex, setCurrIndex] = useState(-1);
  const outsideClickRef = useRef()


  const navigate = useNavigate();

  const params = useParams();
  const dispatch = useDispatch();
  const doctorState = useSelector((store) => store.DoctorSlice);
  const appointmentState = useSelector((store) => store.AppointmentSlice);

  const renderView = (value) => {
    switch (value) {
      case 1:
        return <Review data={doctorState?.single_doctor} />;

      case 2:
        return (
          <>
            <Services data={doctorState?.single_doctor} />
          </>
        );
      case 3:
        return <HealthCare data={doctorState?.single_doctor} />;
      case 4:
        return <AboutUs data={doctorState?.single_doctor} />;

      default:
        break;
    }
  };

  const getpatientAndDoctorBookedData = () => {
    let mappedData = [];

    if (
      appointmentState?.booked_appointments.length > 0 &&
      doctorState?.single_doctor?.appointments.length > 0
    ) {
      for (const patient of appointmentState?.booked_appointments) {
        for (const drAppointMent of doctorState?.single_doctor?.appointments) {
          if (
            patient?.date === startDate &&
            patient?.slot?.id === drAppointMent.slot_id &&
            (patient?.status === 'confirmed' || patient?.status === 'pending')
          ) {
            mappedData.push({
              patient,
              drAppointMent,
            });
          }
        }
      }
    }
    return mappedData;
  };

  const passedTimeSlots =
    doctorSchedule &&
    doctorSchedule[0]?.slots.filter((slot) => {
      const currentTime = new Date();
      // Convert current time and date to comparable formats
      const currentDate = currentTime.toISOString().split('T')[0];

      // Get slot date and time
      const slotDate = doctorSchedule[0]?.date;
      const slotStartTime = slot.start;

      // If the date is today, filter by time
      if (slotDate === currentDate) {
        const slotStartTimeDate = moment(slotStartTime, 'hh:mm A').toDate();
        return slotStartTimeDate > currentTime;
      }

      // If the date is in the future, include all slots
      return slotDate > currentDate;
    });

  const getSchedule = (date) => {
    const formatDate = moment(date).format('YYYY-MM-DD');
    const singleSchedule = doctorState?.single_doctor?.schedule.filter(
      (item) => item.date === formatDate
    );
    return singleSchedule;
  };

  const { handleSubmit } = useForm();

  const submitForm = () => {
    const data = {
      doctor_id: parseInt(params?.id),
      specialist_id: doctorState?.single_doctor?.specialist_id,
      schedule_id: doctorSchedule[0]?.id,
      slot_id: time?.id,
    };
    if (!token) {
      navigate(`/login?drId=${params?.id}`);
    } else {
      dispatch(bookAppointment(data));
    }
  };

  useEffect(() => {
    dispatch(getSpecificDoctor(params?.id));
  }, [dispatch, params?.id]);

  useEffect(() => {
    // Get today's date in 'YYYY-MM-DD' format
    const today = moment().format('YYYY-MM-DD');

    // Filter upcoming schedules
    const upcomingSchedules = doctorState?.single_doctor?.schedule.filter(
      (schedule) => moment(schedule.date).isAfter(today)
    );

    setUpcomingDates(upcomingSchedules);
  }, [doctorState?.single_doctor]);

  useEffect(() => {
    if (
      !appointmentState?.is_loading.loading &&
      appointmentState?.is_success.loading
    ) {
      navigate('/dashboard');
    }
    if (doctorState?.reviewed) {
      dispatch(getSpecificDoctor(params?.id));
    }
  }, [dispatch, params?.id, appointmentState, doctorState]);

  useEffect(() => {
    if (token) {
      dispatch(getBookedAppointments());
    }
  }, [dispatch, token]);

  useEffect(() => {
    const formatDateFilter = moment(startDate).format('YYYY-MM-DD');
    setDoctorSchedule(getSchedule(formatDateFilter));
    if (doctorSchedule && startDate !== doctorSchedule[0]?.date) {
      setCurrIndex(-1);
    }
  }, [startDate, doctorState]);

  const handleClickOutSide = (event) => {
    if (
      outsideClickRef.current &&
      !outsideClickRef.current.contains(event.target)
    ) {
      setCurrentValue(-1);
      setCurrIndex(-1);
      setDoctorSchedule([])
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return ()=>{
      document.addEventListener("mousedown",handleClickOutSide);
    }
  }, []);

  const noSchedules =
    doctorSchedule === null ||
    doctorSchedule === undefined ||
    doctorSchedule === 'null' ||
    doctorSchedule === 'undefined' ||
    doctorSchedule?.length === 0 ||
    passedTimeSlots?.length === 0;

  const patientBooking = getpatientAndDoctorBookedData();

  const disable =
    // noSchedules ||
    // patientBooking.length > 0 ||
    // passedTimeSlots?.length === 0 ||
    currentValue === -1;

  const handleDateChange = (e) => {
    setStartDate(e.target.value);
    const formatDateFilter = moment(startDate).format('YYYY-MM-DD');
    const schedule = getSchedule(formatDateFilter);
    setDoctorSchedule(schedule);
  };

  const handleBookTime = (item, index) => {
    setCurrentValue(index);
    setTime(item);
  };

  const handleClickUpcomingDates = (item, ind) => {
    setDoctorSchedule([item]);
    setStartDate(item?.date);
    setCurrIndex(ind);
  };



  return (
    <div className="dr-detailer-card-wrapper">
      <div className="layout">
        <div className="left-right-section">
          <DoctorCard
            data={doctorState?.single_doctor}
            hideRightSection={true}
            widthHandle="drcard-width-handle"
          />
          <div className="book-your-appointment-wrapper" ref={outsideClickRef}> 
            <div className="book-apointment-container">
              <div className="py-4">
                <h5 className=""> Book Appointment</h5>
              </div>

              <div className="books-form">
                <form onSubmit={handleSubmit(submitForm)}>
                  <p className="">Please login to book an appointment</p>
                  <div className="slots-book-color-info">
                    <div className="color-info confirmed-slot">Confirmed</div>
                    <div className="color-info pending-slot">Booked</div>
                    <div className=" color-info availabel-slot">Available</div>
                  </div>
                  <input
                    type="date"
                    min={today}
                    value={startDate}
                    onChange={handleDateChange}
                    onKeyDown={() => false}
                  />
                  <div className="upcoming-dates-tabs-wrapper">
                    <h3>Upcoming appointment dates</h3>
                    <div className="upcoming-dates-slider-container">
                      {
                         upcomingDates?.length  > 0 && (   
                         <div className="upcoming-dates-slider">
                          {upcomingDates?.map((schedule, index) => (
                            <button
                              type="button"
                              className={`date-tab ${
                                currIndex === index ? 'active-tab' : ''
                              }`}
                              onClick={() =>
                                handleClickUpcomingDates(schedule, index)
                              }
                            >
                              {moment(schedule.date).format('MMMM DD YYYY')}
                            </button>
                          ))}
                        </div>)
                      }
                   
                    </div>
                    {
                      upcomingDates?.length === 0 && (<p className='noUpcoming'>No Upcoming schedules</p>)
                    }
                  </div>

                  <div className="time-slots">
                    {!noSchedules &&
                      doctorSchedule[0]?.slots
                        .filter((slot) => {
                          const currentTime = new Date();
                          // Convert current time and date to comparable formats
                          const currentDate = currentTime
                            .toISOString()
                            .split('T')[0];

                          // Get slot date and time
                          const slotDate = doctorSchedule[0]?.date;
                          const slotStartTime = slot.start;

                          // If the date is today, filter by time
                          if (slotDate === currentDate) {
                            const slotStartTimeDate = moment(
                              slotStartTime,
                              'hh:mm A'
                            ).toDate();
                            return slotStartTimeDate > currentTime;
                          }

                          // If the date is in the future, include all slots
                          return slotDate > currentDate;
                        })
                        .map((item, index) => {
                          const isBooked =
                            doctorState?.single_doctor?.appointments?.some(
                              (appointment) =>
                                appointment.schedule_id ===
                                  doctorSchedule[0]?.id &&
                                appointment.slot_id === item.id &&
                                appointment.status === 'confirmed'
                            );
                          const isPending =
                            doctorState?.single_doctor?.appointments?.some(
                              (appointment) =>
                                appointment.schedule_id ===
                                  doctorSchedule[0]?.id &&
                                appointment.slot_id === item.id &&
                                appointment.status === 'pending'
                            );
                          const slotClassName = `booking-time ${
                            currentValue === index ? 'selected-time' : ''
                          } ${
                            isBooked
                              ? 'disabled-time-slot'
                              : 'not-disable-time-slot'
                          } ${
                            isPending ? 'pending-time-slot' : 'booking-time'
                          }`;

                          return (
                            <div
                              className={slotClassName}
                              key={item.id}
                              onClick={() => {
                                if (!isBooked) {
                                  handleBookTime(item, index);
                                }
                              }}
                            >
                              {item?.start}
                            </div>
                          );
                        })}

                    {noSchedules && <p>There is no Schedules for today </p>}
                  </div>
                  <button
                    type="submit"
                    className={`
                       ${disable ? 'disableBtn' : ''} 
                      ${appointmentState.is_loading.loading &&
                      !appointmentState.is_success.loading
                        ? 'not-allow'
                        : ''
                    }`}
                    disabled={disable}
                  >
                    {appointmentState.is_loading.loading &&
                    !appointmentState.is_success.loading ? (
                      <div className="spinner"></div>
                    ) : (
                      'Book Now'
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="tabs-section">
            <div className="tabs-wrapper">
              <div
                className={`tab ${currentTab === 1 && 'activeTab'}`}
                onClick={() => setCurrentTab(1)}
              >
                Review
              </div>
              <div
                className={`  tab ${currentTab === 2 && 'activeTab'}`}
                onClick={() => setCurrentTab(2)}
              >
                Services
              </div>
              <div
                className={` tab  ${currentTab === 3 && 'activeTab'}`}
                onClick={() => setCurrentTab(3)}
              >
                Experience
              </div>
              <div
                className={` tab  ${currentTab === 4 && 'activeTab'}`}
                onClick={() => setCurrentTab(4)}
              >
                About Us
              </div>
            </div>
            <div className="row">{renderView(currentTab)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetails;
