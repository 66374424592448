import React from 'react';
import parse from 'html-react-parser';
import "../../doctorDetails.css"

const AboutUs = ({data}) => {
  return (
    <div className="desc-card">
      <h5 className="heading">About Us</h5>
      {parse(`${data?.about || ""} `)}
    </div>
  );
};

export default AboutUs;
