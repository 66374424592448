import React, { useEffect, useState } from 'react';
import './loginForm.css';
import kenLogo from '../../assets/images/kenlogo.png';
import { useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'react-feather';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formOptions } from './helper';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/Features/Auth/AuthApi';
import ErrorText from '../../shared/ErrorText/ErrorText';
import { TosatMessage } from '../../helpers/Toast';
import { getLandingPageTopbardetails } from '../../store/Features/LandingPage/LandingPageApi';
import { falseLogoutState } from '../../store/Features/Auth/AuthSlice';

const LoginForm = () => {
  const location = useLocation();

  const { handleSubmit, register, formState } = useForm(formOptions);
  const { errors } = formState;
  const dispatch = useDispatch();
  const authState = useSelector((store)=>store.AuthSlice);
  const landingPageState = useSelector((store) => store.LandingPageSlice);
  const isVerified = new URLSearchParams(location?.search).get("isVerified");
  const isFromDrDetails = new URLSearchParams(location?.search).get("drId");

  const navigate = useNavigate();

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("email",values?.email);
    formData.append("password",values?.password);
    dispatch(loginUser(formData));
  };

  const [eyeState, setEyeState] = useState(true);
  const toggleEye = () => {
    setEyeState(!eyeState);

  };

  useEffect(()=>{
    if(authState?.is_authenticated){
      if(isFromDrDetails !==null){
        navigate(`/doctor-details/${isFromDrDetails}`)
      }
      else{

        navigate("/")
      }
    }
  },[authState?.is_authenticated]);

  useEffect(()=>{
    dispatch(getLandingPageTopbardetails());
    dispatch(falseLogoutState());

  },[])

  useEffect(()=>{
    if(isVerified === "true"){
      const message = {
        status:true,
        message:"Your email has been verified.Now You can Login."
      }
      TosatMessage(message)
    }
    else if(isVerified === "false"){
      const message = {
        status:false,
        message:"Your email does not verified.Please check your email"
      }
      TosatMessage(message)

    }

  },[isVerified])

  return (
    <div className="auth__wrapper">
      <form className="auth__container" onSubmit={handleSubmit(onSubmit)}>
        <div className="auth__header">
          <div className="half__logo" onClick={()=>navigate("/")}>
            <img src={   landingPageState?.landingPageTopBarDetails?.main_logo || kenLogo} alt="Logo" />
          </div>
          <h3>Login to Kencenter </h3>
          <p className="auth__text">
            Welcome back! Log in to Kencenter and continue your journey.
          </p>
        </div>
        <div className="form__row">
          <div className="input__group">
            <div className="group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                className={`${errors?.email && "is-invalid"}`}
                {...register("email")}
              />
            </div>
            {
              errors?.email && (<ErrorText error={errors?.email?.message}/>)
            }
          </div>
          <div className="input__group">
            <div className="group">
              <label htmlFor="password">Password</label>
              <input type={eyeState ?"password":"text"}
                  className={`${errors?.password && "is-invalid"}`}
               name="password" {...register("password")} />
              <span
                className="passwordToggle"
                id="passwordToggle"
                onClick={toggleEye}
              >
                {eyeState ? <Eye /> : <EyeOff />}
              </span>
            </div>
            {
              errors?.password &&
              (<ErrorText error={errors?.password?.message}/>)
            }

             
          </div>
        </div>
        <div className="forgot" >
          <span onClick={()=>navigate("/forgot-password")}>Forgot password?</span>
        </div>
        <button type="submit" className={authState.is_loading.login ? "disabled":""}>
          {authState.is_loading.login ? <div className="spinner"></div> :"Login"}
          
        </button>
        {/* <PrimaryButton type={"submit"} text={"Login"}/> */}
        <p className="dont__text">
          Don’t have an account?
          <span onClick={()=>navigate("/register")}> Register now</span>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
