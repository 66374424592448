/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./index.css"

import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { getLandingPageTopbardetails } from '../../../../store/Features/LandingPage/LandingPageApi';
import { getUser } from '../../../../store/Features/Auth/AuthApi';
import { useNavigate, useParams } from 'react-router-dom';
import { getBookedAppointments } from '../../../../store/Features/Appointment/AppointmentApi';
import moment from 'moment';
import { ChevronLeft } from 'react-feather';

const AppointMentpdf = () => {

  const navivate = useNavigate();
  const componentRef = useRef();

  const params = useParams();

  const id = Number(params?.id);

  
  const dispatch = useDispatch();
  const landingPageState = useSelector((store) => store.LandingPageSlice);
  const appointmentState = useSelector((store)=>store.AppointmentSlice);
  const authState = useSelector((store) => store.AuthSlice);
  const single_appointemt = appointmentState?.booked_appointments?.filter((item)=>item?.id === id);



  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printDocument = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      //page width
      const pageWidth = 210;
      const pageHeight = 297;

      const height = canvas.height * pageWidth / canvas.width;
      const pdf = new jsPDF("p","mm","a4");
      pdf.addImage(imgData, "PNG", 0, 0,pageWidth,height);
      pdf.save("download.pdf");
    });
  };


  useEffect(() => {
    dispatch(getUser());
    dispatch(getLandingPageTopbardetails());
    dispatch(getBookedAppointments());
  }, []);


  return (
    <div className="apt__wrapper">
      <div className="goback" onClick={()=>navivate("/dashboard")}>
        <ChevronLeft/>
        <span>Back</span>
      </div>
      <div className="apt__infos layout" ref={componentRef}>
        <div className="apt__header">
          <div className="image">
            <img
              src={landingPageState?.landingPageTopBarDetails?.main_logo}
              alt=""
            />
          </div>
          <div className="apt__right">
            <p className="apt__id">
            Appointment ID <span> #{single_appointemt[0]?.id}</span>
            </p>
            <p className="bookin__date">
              Booking Date: <span>{moment(single_appointemt[0]?.created_at).format("DD/MM/YYYY")}</span>
            </p>
          </div>
        </div>

        <div className="apt__location__patient__info">
          <div className="apt__location">
          
            {landingPageState?.landingPageTopBarDetails?.address}
          </div>
          <div className="patient__info">
            <h3>Patient Information</h3>
            <div className="patient__info">
              <p className="address">{authState?.user?.fullname}</p>
              <p className="address">{authState?.user?.address}</p>
              <p className="age">Age: {authState?.user?.age}yrs | {authState?.user?.gender}</p>
              <p className="patient__ph__number">+977 981 234 5678</p>
            </div>
          </div>
        </div>

        <div className="apt__details">
          <h4>Appointment Details</h4>
          <div className="apt__detail">
            <p>
              Doctor Name: <span>{single_appointemt[0]?.doctor_name}</span>
            </p>
            <p>
              Appointment Date: <span>{moment(single_appointemt[0]?.date).format("DD/MM/YYYY")}</span>
            </p>
            <p>
              Appointment Time: <span>{single_appointemt[0]?.slot?.start} - {single_appointemt[0]?.slot?.end}</span>{' '}
            </p>
          </div>
        </div>

        <div className="apt__desc">
          <h5>Description</h5>
          <p>General Consultation on fever</p>
        </div>
        <div className="apt__other__info">
          <h4>Other information</h4>
          <p>Please visit our clinic at scheduled date and time. Incase of any change in the booking details please contact Ken Center</p>
        </div>
        <div className="apt__infos__footer">
            <p className='info'>+977{landingPageState?.landingPageTopBarDetails?.phone} | {landingPageState?.landingPageTopBarDetails?.email}</p>
            <a href='https://kencenter.com.np/' target='_blank' rel="noreferrer">www.kencenter.com.np</a>
        </div>


      </div>
      <div className="download__button" >
            <div className="download__button__width">
              <button type='button' onClick={printDocument}>Download Appointment</button>
            </div>
        </div>
    </div>
  );
};

export default AppointMentpdf;
