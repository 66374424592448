import React from 'react';
import parse from 'html-react-parser';
import "../../doctorDetails.css"

const HealthCare = ({data}) => {
  return (
    <div className="desc-card">
    {/* <h5 className="heading">Health Care</h5> */}
    <h5 className="heading">Experience</h5>

    {parse(`${data?.training || ""}`)}
  </div>
  );
};

export default HealthCare;
