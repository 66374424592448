import React, { useEffect } from 'react'
import LoginForm from '../../components/Login/LoginForm'
import LocalDb from '../../helpers/LocalDB/LocalDb';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const token = LocalDb.fetch('access-token');
  const user = LocalDb.fetch("user");
  const navigate = useNavigate();

  useEffect(()=>{
    if(token && user){
      navigate("/")
    }
  },[])
  return (
   <LoginForm/>
  )
}

export default LoginPage
