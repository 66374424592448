import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../apiConfigs/urlConfigs";
import axios from "axios";

// https://backend.kencenter.com.np/api/seo
export const getSeoDetails = createAsyncThunk("getSeoDetails",async(undefined,{rejectWithValue})=>{
    try {
        const res = await axios.get(`${BASE_URL}/seo`);
        return res.data;
    } catch (error) {
        if (!error.response) {
            throw error;
          }
          return rejectWithValue(error.response.data);
    }
});
