/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from 'react';
import './footer.css';
import FooterBottom from './FooterBottom/FooterBottom';
import logo from '../../../assets/images/kenlogo-white.png';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Facebook, Instagram, Linkedin, Mic, Twitter, Youtube } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLandingPageTopbardetails,
  getSocialMediaLinks,
} from '../../../store/Features/LandingPage/LandingPageApi';

const Footer = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const landingPageState = useSelector((store) => store.LandingPageSlice);
  const socialLinksState = useSelector((store) => store.LandingPageSlice);

  useEffect(() => {
    dispatch(getSocialMediaLinks());
    dispatch(getLandingPageTopbardetails());
  }, [dispatch]);

  return (
    <>
      <footer className="footer__wrapper main__footer">
        <div className=" links__section layout">
          <div className="links__wrapper ">
            <div className="logo__section" onClick={() => navigate('/')}>
              <img
                src={
                  landingPageState?.landingPageTopBarDetails?.footer_logo ||
                  logo
                }
                alt="Kencenter Logo"
              />
              {
                landingPageState?.landingPageTopBarDetails?.description && ( <p className='footer__desc'> {parse(
                  `${landingPageState?.landingPageTopBarDetails?.description}`
                ) || null}</p>)
              }
             
            </div>
            <div className="foot__links ">
              <span className="footul_head uppercase">Quick Links</span>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={'/doctors'}>Doctors</Link>
                </li>
                <li>
                  <Link to={'/blogs'}>Blogs</Link>
                </li>
              </ul>
            </div>

            <div className=" foot__links ">
              <span className="footul_head uppercase">Support</span>
              <ul>
                {/* <li>
                  <Link to={'#'}>Terms & Conditions</Link>
                </li> */}
                <li>
                  <Link to={'/contact'}>Contact</Link>
                </li>
              </ul>
            </div>

            <div className="foot__links ">
              <span className="footul_head email__heading">
                Email us directly at
              </span>
              <div className="support__section">
                <a
                  href={`mailto:${landingPageState?.landingPageTopBarDetails?.email}`}
                  className="mail__link"
                >
                  {landingPageState?.landingPageTopBarDetails?.email}
                </a>
                <div
                  className="fcc_icons social__icons"
                  aria-label="Social links"
                >
                  <a href={socialLinksState?.socialMedialLinks?.facebook} role="link" aria-label="Facebook Icon" target='_blank' rel="noreferrer">
              <Facebook />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.instagram}role="link" aria-label="Instagram Icon" target='_blank' rel="noreferrer">
              <Instagram />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.linkedin}role="link" aria-label="Linkedn icon" target='_blank' rel="noreferrer">
              <Linkedin />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.twitter}role="link" aria-label="Twitter Icon" target='_blank' rel="noreferrer">
              <Twitter />
            </a>
            <a href={socialLinksState?.socialMedialLinks?.youtube} role="link" aria-label="Youtube Icon" target='_blank' rel="noreferrer">
              <Youtube />
            </a>

                  {/* <a href={socialLinksState?.socialMedialLinks?.linkedin} role="link" aria-label="Microphone Icon">
                    <Mic />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <FooterBottom socialLinksState={socialLinksState} />
    </>
  );
};

export default Footer;
