/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import kenLogo from '../../assets/images/kenlogo.png';
import { useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { formOptions } from './helper';
import { useDispatch, useSelector } from 'react-redux';
import {  resetPassword } from '../../store/Features/Auth/AuthApi';
import ErrorText from '../../shared/ErrorText/ErrorText';
import { getLandingPageTopbardetails } from '../../store/Features/LandingPage/LandingPageApi';

const ResetPasswordForm = () => {
    const [eyeState, setEyeState] = useState(true);
    const [confirmEyeState, setConfirmSysState] = useState(true);
    

  const params = useParams();

  const {token} = params;


  const { handleSubmit, register, formState } = useForm(formOptions);
  const { errors } = formState;
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.AuthSlice);
  const landingPageState = useSelector((store) => store.LandingPageSlice);

  const navigate = useNavigate();

  const onSubmit = (values) => {
    const data = {
        ...values,
        token
    }
    dispatch(resetPassword(data));
    // reset()
  };

  const toggleEye = () => {
    setEyeState(!eyeState);
  };
  const toggleConfirmEye = () => {
    setConfirmSysState(!confirmEyeState);
  };

  useEffect(()=>{
    if(authState?.is_success.reset_password){
        navigate("/login");
    }
  },[authState]);

  useEffect(()=>{
    dispatch(getLandingPageTopbardetails());

  },[])

  return (
    <div className="auth__wrapper">
      <form className="auth__container" onSubmit={handleSubmit(onSubmit)}>
        <div className="auth__header">
          <div className="half__logo" onClick={() => navigate('/')}>
            <img src={landingPageState?.landingPageTopBarDetails?.main_logo ||  kenLogo} alt="Logo" />
          </div>
          <h3>Reset Password </h3>
          <p className="auth__text">
            Oh! You Forgot password
          </p>
        </div>
        <div className="form__row">
          <div className="input__group">
            <div className="group">
              <label htmlFor="password">Password</label>
              <input
                type={eyeState ? 'password' : 'text'}
                className={`${errors?.password && 'is-invalid'}`}
                name="password"
                {...register('password')}
              />
              <span
                className="passwordToggle"
                id="passwordToggle"
                onClick={toggleEye}
              >
                {eyeState ? <Eye /> : <EyeOff />}
              </span>
            </div>
            {errors?.password && (
              <ErrorText error={errors?.password?.message} />
            )}
          </div>

          <div className="input__group">
            <div className="group">
              <label htmlFor="password">Confirm Password</label>
              <input
                type={confirmEyeState ? 'password' : 'text'}
                className={`${errors?.password_confirmation && 'is-invalid'}`}
                name="password_confirmation"
                {...register('password_confirmation')}
              />
              <span
                className="passwordToggle"
                id="passwordToggle"
                onClick={toggleConfirmEye}
              >
                {confirmEyeState ? <Eye /> : <EyeOff />}
              </span>
            </div>
            {errors?.password_confirmation && (
              <ErrorText error={errors?.password_confirmation?.message} />
            )}
          </div>
        </div>

        <button type="submit" className={authState.is_loading.reset_password ? "disabled":""}>
          
          {authState.is_loading.reset_password ? <div className="spinner"></div> :"Reset"}
        </button>
      </form>
    </div>
  );
};
export default ResetPasswordForm;
