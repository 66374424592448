import { createSlice } from '@reduxjs/toolkit';
import {
  contact,
  getFaqs,
  getHeroSliderData,
  getLandingPageTopbardetails,
  getNotices,
  getSocialMediaLinks,
  getTestimonials,
} from './LandingPageApi';

const initialState = {
  data: [],
  loading: false,
  landingPageTopBarDetails: null,
  socialMedialLinks: null,
  faqData: [],
  heroSliderData: [],
  notices:null,
  contact:null,
  testimonialData:[]
};

const landingPageSlice = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET HEADER TOPBAR DETAILS
    builder.addCase(getLandingPageTopbardetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLandingPageTopbardetails.fulfilled, (state, action) => {
      state.loading = false;
      state.landingPageTopBarDetails = action.payload.data;
    });
    builder.addCase(getLandingPageTopbardetails.rejected, (state, action) => {
      state.loading = false;
    });

    //GET SOCIAL MEDIA LINKS DETAILS
    builder.addCase(getSocialMediaLinks.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSocialMediaLinks.fulfilled, (state, action) => {
      state.loading = false;
      state.socialMedialLinks = action.payload.data;
    });
    builder.addCase(getSocialMediaLinks.rejected, (state, action) => {
      state.loading = false;
    });

    //GET FAQ DATA
    builder.addCase(getFaqs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFaqs.fulfilled, (state, action) => {
      state.loading = false;
      state.faqData = action.payload.data;
    });
    builder.addCase(getFaqs.rejected, (state, action) => {
      state.loading = false;
    });

    //GET HERO SLIDER DATA
    builder.addCase(getHeroSliderData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getHeroSliderData.fulfilled, (state, action) => {
      state.loading = false;
      state.heroSliderData = action.payload.data;
    });
    builder.addCase(getHeroSliderData.rejected, (state, action) => {
      state.loading = false;
    });

        //GET Notices  DATA
        builder.addCase(getNotices.pending, (state, action) => {
          state.loading = true;
        });
        builder.addCase(getNotices.fulfilled, (state, action) => {
          state.loading = false;
          state.notices = action.payload.data;
        });
        builder.addCase(getNotices.rejected, (state, action) => {
          state.loading = false;
        });
        
        //CONTACT
        builder.addCase(contact.pending, (state, action) => {
          state.loading = true;
        });
        builder.addCase(contact.fulfilled, (state, action) => {
          state.loading = false;
          state.contact = action.payload.data;
        });
        builder.addCase(contact.rejected, (state, action) => {
          state.loading = false;
        });

          //CONTACT
          builder.addCase(getTestimonials.pending, (state, action) => {
            state.loading = true;
          });
          builder.addCase(getTestimonials.fulfilled, (state, action) => {
            state.loading = false;
            state.testimonialData = action.payload.data;
          });
          builder.addCase(getTestimonials.rejected, (state, action) => {
            state.loading = false;
          });
  },
});

export default landingPageSlice.reducer;
