import React from 'react';
import { Loader } from 'react-feather';
import "./index.css"

const SuspenseLoader = () => (
    <div className="loader-div">
        {/* <Loader /> */}
        <div className="loader"></div>
    </div>
);

export default SuspenseLoader;